import { React, useEffect, useState, useRef } from 'react';
import axios from "axios";

import Footer from './Footer';
import Navbar from './Navbar';


function Team() {
    const [listOfTestimonial, setListOfTeam] = useState([]);

    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/TeamActiveGet.php").then((response) => {
            setListOfTeam(response.data);
            console.log(response.data);
        })
    }, [])
    return (
        <div>

            <div className="hero page-inner overlay"
                style={{ backgroundImage: `url('../../Content/img/slide/BNT5.jpg')` }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9 text-center mt-5">
                            <h1 className="heading" data-aos="fade-up">Team</h1>

                            <nav aria-label="breadcrumb"
                                data-aos="fade-up"
                                data-aos-delay="200">
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-5 bg-light ">
                <div className="container pb-4 " data-aos="fade-up">
                    <div className="row justify-content-center text-center mb-5" data-aos="fade-up">
                        <div className="col-lg-6 mb-3">
                            <h2 className="font-weight-bold heading text-primary mb-4">
                                Meet Our Dedicated Team
                            </h2>
                            <h6 className="text-black-50">
                                Get to know the experts behind our mission. Meet our passionate team dedicated to your mental health and mental wealth journey.
                            </h6>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up">
                        {listOfTestimonial.map((value, key) => {
                            const rating = value.rating; // Assuming rating is the key in your database

                            // Create an array with the number of stars equal to the rating
                            const stars = Array.from({ length: rating }, (_, i) => (
                                <span key={i} className="icon-star text-warning pr-1"></span>
                            ));

                            // Fill the remaining stars with black-50
                            const remainingStars = Array.from({ length: 5 - rating }, (_, i) => (
                                <span key={rating + i} className="icon-star text-black-50 pr-1"></span>
                            ));

                            return (
                                <div className="col-sm-6 col-md-6 col-lg-4 my-6 " key={key}>
                                    <div className="h-100 person">
                                        <div className="personImgs">




                                            {value.image ?
                                                (<img className="img-fluid" src={`https://bntserver.bntfoundation.com/${value.image}`} width="100%" style={{ padding: "0px", borderRadius: '40px' }} />) :
                                                (<img className="img-fluid" src='Content/img/temp image.jpg' width="100%" style={{ padding: "0px" }} />)
                                            }
                                        </div>

                                        <div className="person-contents pt-0">
                                            <h2 className="mb-0">{value.name}</h2>
                                            <span className="meta d-block mt-1 mb-2">{value.profession}</span>

                                            <p className="text-Greey">
                                                {value.bio}
                                            </p>


                                            <ul className="social list-unstyled list-inline dark-hover">
                                                <li className="list-inline-item">
                                                    <a href={`mailto:${value.email}`}><span className="icon-envelope"></span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                )
                        })}
                        






                    </div>
                </div>
            </div>

        </div>
    )
}

export default Team;