import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Payment() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [addValues, setAddValues] = useState('');

  const initialValues = {
    name: '',
    email: '',
    address: '',
    paymentmethod: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    address: Yup.string().required(),
    paymentmethod: Yup.string().required(),
  });

  useEffect(() => {
    axios
      .get('https://bntserver.bntfoundation.com/PaymentGet.php')
      .then((response) => {
        setListOfItems(response.data);
      });
  }, [reducerValue]);
  const handleSubmit = (value) => {
    setAddValues(value);
  };
  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };

  const onSubmit = async (data, { setSubmitting, resetForm }) => {
    await axios.post('http://localhost:3001/payment', data);
    forceUpdate();
    showTostify('Payment saved');
    resetForm();
  };
  const onUpdate = async (data) => {
    try {
      await axios.put('http://localhost:3001/payment', data);
      window.location.reload();
    } catch (error) {
      console.error('Error updating payment', error);
    }
  };

  const handleEditClick = (value) => {
    setEditValues(value);
  };

  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };

  const hideModalScreen = () => {};

  const onDelete = () => {
    axios
      .delete(
        `https://bntserver.bntfoundation.com/ContactDelete.php?id=${currentClickedId}`
      )
      .then((response) => {
        setListOfItems(
          listOfItems.filter(function (item) {
            return item.id != currentClickedId;
          })
        );
      });

    toast.success('Item deleted successfully', {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
    hideModalScreen();
  };
  return (
    <div className="AdminContainer container">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}

        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p>Are you sure, you want to delete?</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}

        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="name" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="name">
                            Name
                          </label>

                          <Field
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter a name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="email" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>

                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Enter a email"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="address" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="address">
                            Address
                          </label>

                          <Field
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Enter a address"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="paymentmethod" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="paymentmethod">
                            Payment Method
                          </label>

                          <Field
                            type="text"
                            name="paymentmethod"
                            id="paymentmethod"
                            placeholder="Enter a paymentMethod"
                            class="form-control"
                          />
                        </div>

                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Edit */}

        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="name">
                        Name
                      </label>

                      <b name="name" className="mx-2">
                        {editValues.name}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>

                      <b name="email" className="mx-2">
                        {editValues.email}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="address">
                        Address
                      </label>

                      <b name="address" className="mx-2">
                        {editValues.address}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="paymentmethod">
                        Payment Method
                      </label>

                      <b name="paymentmethod" className="mx-2">
                        {editValues.paymentmethod}
                      </b>
                    </div>

                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}

        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Payment
              </h2>
              <p class="text-center"> Manage Payment here.</p>
            </div>
          </div>

          <div class="row">
            <div
              class="modal fade"
              id="exampleSubmitModal"
              tabindex="-1"
              aria-labelledby="exampleSubmitModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleSubmitModalLabel">
                      Add Contact
                    </h5>
                    <button
                      type="button"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                      style={{ backgroundColor: 'white', border: 'none' }}
                    >
                      <i class="fa-solid fa-xmark" style={{ fontSize: 22 }}></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                    >
                      {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                          <ErrorMessage name="name" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="name">
                              Name
                            </label>

                            <Field
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Enter a name"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="email" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="email">
                              Email
                            </label>

                            <Field
                              type="text"
                              name="email"
                              id="email"
                              placeholder="Enter a email"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="address" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="address">
                              Address
                            </label>

                            <Field
                              type="text"
                              name="address"
                              id="address"
                              placeholder="Enter a address"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="paymentmethod" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="paymentmethod">
                              Payment Method
                            </label>

                            <Field
                              type="text"
                              name="paymentmethod"
                              id="paymentmethod"
                              placeholder="Enter a paymentMethod"
                              class="form-control"
                            />
                          </div>

                          <button
                            type="submit"
                            class="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Add
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <button
                class="btn btn-primary mx-3"
                data-bs-toggle="modal"
                data-bs-target="#exampleSubmitModal"
                onClick={() => handleSubmit()}
              >
                Add
              </button>
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Payment List</h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>name</th>
                        <th>email</th>
                        <th>address</th>
                        <th>paymentMethod</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>{value.name}</td>
                            <td>{value.email}</td>
                            <td>{value.address}</td>
                            <td>{value.paymentmethod}</td>

                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  {/* <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Edit
                                  </Link> */}

                                  {/* <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link> */}

                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Payment;
