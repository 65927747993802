import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";



function EditBlog() {

    let { id } = useParams();
    const [BlogObject, setBlogObject] = useState({});

    useEffect(() => {
        axios.get(`https://bntserver.bntfoundation.com/BlogPut.php?id=${id}`).then((response) => {
            setBlogObject(response.data);
        })
    }, [id])

    const onUpdate = async (data) => {
        try {
            const formData = new FormData();
            formData.append("id", data.id);
            formData.append("name", data.name);
            formData.append("blogtitle", data.blogtitle);
            formData.append("blogdesc", data.blogdesc);
            formData.append("blogtitle2", data.blogtitle2);
            formData.append("blogdesc2", data.blogdesc2);
            formData.append("blogtitle3", data.blogtitle3);
            formData.append("blogdesc3", data.blogdesc3);
            formData.append("writerpic", data.writerpic);
            formData.append("writername", data.writername);
            formData.append("writerdesc", data.writerdesc);
            formData.append("sidedesctitle", data.sidedesctitle);
            formData.append("sidedesc", data.sidedesc);
            formData.append("isactive", data.isactive);
            formData.append("priority", data.priority);
            formData.append("blogurllink", data.blogurllink);
            formData.append("blogpic", data.blogpic);


            await axios.put("http://localhost:3001/Blogs", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            window.location.href = '/bloglist';

        } catch (error) {
            console.error("Error updating establishment:", error);
        }
    };
    const validation1 = Yup.object().shape({
        name: Yup.string().required(),
        blogtitle: Yup.string().required(),
        blogdesc: Yup.string().required(),
        blogtitle2: Yup.string().required(),
        blogdesc2: Yup.string().required(),
        writername: Yup.string().required(),
        isactive: Yup.string().required(),
        priority: Yup.number().min(0).max(50).required(),
    })


    return (
        <div>
            <h2>Edit Blog</h2>



            <Formik initialValues={BlogObject} onSubmit={onUpdate} enableReinitialize={true} validationSchema={validation1}>
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">
                            <div className="form-group">
                                <lable className="control-label col-md-2">Name</lable>
                                <div className="col-md-10">
                                    <ErrorMessage name="name" component="span" className='text-danger' />
                                    <Field

                                        name="name"

                                        class="form-control"

                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <lable className="control-label col-md-2">Blog Image</lable>

                                <div className="col-md-10">
                                    <div className='my-1'></div>
                                    <input type="file" name="blogpic" onChange={(event) => {
                                        setFieldValue("blogpic", event.target.files[0]);
                                    }} />
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Blog Title
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="blogtitle" component="span" className='text-danger' />
                                    <Field

                                        name="blogtitle"
                                        id="blogtitle"

                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Blog Desc
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="blogdesc" component="span" className='text-danger' />
                                    <Field

                                        name="blogdesc"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2">
                                    Blog Title 2
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="blogtitle2" component="span" className='text-danger' />
                                    <Field

                                        name="blogtitle2"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Blog Desc 2
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="blogdesc2" component="span" className='text-danger' />
                                    <Field

                                        name="blogdesc2"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Blog Title 3
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="blogtitle3" component="span" className='text-danger' />
                                    <Field

                                        name="blogtitle3"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Blog Desc 3
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="blogdesc3" component="span" className='text-danger' />
                                    <Field

                                        name="blogdesc3"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Writer Pic
                                </label>

                                <div className="col-md-10">
                                    
                                    <input
                                        type="file"
                                        onChange={(event) => {
                                            setFieldValue("writerpic", event.target.files[0]);
                                        }}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Writer Name
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="writername" component="span" className='text-danger' />
                                    <Field

                                        name="writername"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2" >
                                    Writer Desc
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="writerdesc" component="span" className='text-danger' />
                                    <Field

                                        name="writerdesc"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2">
                                    Side Desc Title
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="sidedesctitle" component="span" className='text-danger' />
                                    <Field

                                        name="sidedesctitle"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2">
                                    Side Desc
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="sidedesc" component="span" className='text-danger' />
                                    <Field

                                        name="sidedesc"


                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-md-2">
                                    Blog Url Link
                                </label>

                                <div className="col-md-10">
                                    <ErrorMessage name="blogurllink" component="span" className='text-danger' />
                                    <Field

                                        name="blogurllink"


                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="Priority" />
                                </div>
                            </div>





                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="isactive" component="span" className='text-danger' />
                                    <Field as="select" name="isactive" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="true" label="true"></option>
                                        <option value="false" label="false"></option>
                                    </Field>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                        type="submit"

                        className="btn btn-primary"
                    >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default EditBlog;