import React from 'react'
import { Link, Route, useNavigate } from 'react-router-dom'
import ROUTES from '../../Nav/Routes';
import { useState, useEffect } from 'react';
import axios from 'axios';

function Navbar() {
    const navigate = useNavigate();


    const handleMenuToggle = () => {
        const mobileMenu = document.querySelector('.site-mobile-menu');
        const mobileback = document.querySelector('.mobileGround');
        if (mobileMenu) {
            mobileMenu.classList.toggle('siteMenuClose');
            mobileback.classList.toggle('d-none');
        }
    };

    const handleCloseMenu = () => {
        const mobileMenu = document.querySelector('.site-mobile-menu');
        const mobileback = document.querySelector('.mobileGround');
        if (mobileMenu) {
            mobileMenu.classList.add('siteMenuClose');
            mobileback.classList.add('d-none');  // Add siteMenuClose to hide the menu
        }
    };
    useEffect(() => {
        // Close the menu if clicked outside
        const handleOutsideClick = (event) => {
            const mobileMenu = document.querySelector('.site-mobile-menu');
            const menuOpen = mobileMenu && !mobileMenu.classList.contains('siteMenuClose');
            const burgerClicked = document.querySelector('.burger').contains(event.target);
            const insideMenu = mobileMenu && mobileMenu.contains(event.target);
            const mobileback = document.querySelector('.mobileGround');

            if (menuOpen && !insideMenu && !burgerClicked) {
                mobileMenu.classList.add('siteMenuClose'); // Close the menu
                mobileback.classList.add('d-none');  // Add siteMenuClose to hide the menu
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleClickTop = () => {
        window.scrollTo(0, 0);

        const mobileMenu = document.querySelector('.site-mobile-menu');
        const mobileback = document.querySelector('.mobileGround');
        if (mobileMenu) {
            mobileMenu.classList.add('siteMenuClose');
            mobileback.classList.add('d-none');  // Add siteMenuClose to hide the menu
        }
    };

    const handleRefresh = () => {
        // window.location.reload();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        window.scrollTo(0, 0);
    };

    return (
        <div>
            {/* <div className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-header">
                <div className="site-mobile-menu-close">
                <span className="icofont-close js-menu-toggle"></span>
                </div>
                </div>
                <div className="site-mobile-menu-body">
                
                </div>
                </div> */}





            <div className='mobileGround d-none'></div>



            <div className="site-mobile-menu site-navbar-target siteMenuClose active">
                <div className="site-mobile-menu-header">
                    <a className="site-mobile-menu-close" onClick={handleCloseMenu}>
                        <span className="icofont-close js-menu-toggle"></span>
                    </a>
                </div>
                <div className="site-mobile-menu-body">
                    <ul className="site-nav-wrap">
                        <li><Link to={ROUTES.index.name} onClick={handleRefresh}>Home</Link></li>
                        <li><Link to={ROUTES.about.name} onClick={handleClickTop}>About</Link></li>
                        <li><Link to={ROUTES.blog.name} onClick={handleClickTop}>Blog</Link></li>
                        <li><Link to={ROUTES.gallery.name} onClick={handleClickTop}>Gallery</Link></li>
                        <li><Link to={ROUTES.contact.name} onClick={handleClickTop}>Contact</Link></li>
                        <li><Link to={ROUTES.team.name} onClick={handleClickTop}>Team</Link></li>
                        <li><Link to={ROUTES.donation.name} onClick={handleClickTop}>Donate</Link></li>
                        {/* <li><Link to={ROUTES.home.name} onClick={handleClickTop}>Login</Link></li> */}
                        <li>  <Link to={ROUTES.gettheapp.name} onClick={handleClickTop} className='btn btn-nav text-white py-2 px-3' >Get Saathi App</Link></li>
                    </ul>
                </div>
            </div>








            <nav className="site-nav ">
                <div className="afterAbsolute">
                    <div className="container absolute ">
                        <div className="menu-bg-wrap ">
                            <div className="site-navigation  ">
                                <Link to={ROUTES.index.name} onClick={handleRefresh} className="logo m-0 float-start">

                                    <img src="Content/img/Logo3.png" className='logoImage' />
                                </Link>

                                <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
                                    <li><Link to={ROUTES.index.name} onClick={handleRefresh}>Home</Link></li>
                                    <li><Link to={ROUTES.about.name} onClick={handleClickTop}>About</Link></li>
                                    <li><Link to={ROUTES.blog.name} onClick={handleClickTop}>Blog</Link></li>
                                    <li><Link to={ROUTES.gallery.name} onClick={handleClickTop}>Gallery</Link></li>
                                    <li><Link to={ROUTES.contact.name} onClick={handleClickTop}>Contact</Link></li>
                                    <li><Link to={ROUTES.team.name} onClick={handleClickTop}>Team</Link></li>
                                    <li><Link to={ROUTES.donation.name} onClick={handleClickTop}>Donate</Link></li>
                                    {/* <li><Link to={ROUTES.home.name} onClick={handleClickTop}>Login</Link></li> */}
                                    <li>  <Link to={ROUTES.gettheapp.name} onClick={handleClickTop} className='btn btn-nav text-white py-2 px-3' >Get Saathi App</Link></li>
                                </ul>

                                <a
                                    className="burger light mt-1  d-inline-block d-lg-none"
                                    onClick={handleMenuToggle}
                                >
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>


        </div>
    )
}

export default Navbar;