import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeamList = () => {
    // This is for get data list
    const [listOfTestimonial, setListOfTestimonial] = useState([]);
    const [editValues, setEditValues] = useState(null);
    const [currentId, setCurrentId] = useState("");
    const notifyDelete = () => toast("Item Deleted Successfully!", {
        hideProgressBar: false,
        pauseOnHover: false,
        hideProgressBar: true,
        style: {
            style: {
                color: '#008000',
            }
        }
    });


    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/TeamGet.php").then((response) => {
            setListOfTestimonial(response.data);
        })
    }, [listOfTestimonial])
    
    let navigate = useNavigate();
    //for delete request
    const deleteContact = () => {
        axios.delete(`https://bntserver.bntfoundation.com/TeamDelete.php?id=${currentId}`).then(() => {
            // listOfContacts(response.data);
            setListOfTestimonial(listOfTestimonial.filter(function (item) {
                return item.id !== currentId;
            }))
        }).then(() => {
            hideDeleteModal();
            notifyDelete();
        })

    }

    const handleEditClick = (value) => {
        setEditValues(value);
    };

    const showDeleteModal = (testimonialId) => {
        document.getElementById("deleteModal").style.display = "block";
        setCurrentId(testimonialId);

    }
    const hideDeleteModal = () => {
        document.getElementById("deleteModal").style.display = "none";
    }
    return (
        <div className='w-100'>
            <div className="modal " id='deleteModal'>
                <div className="modal-dialog shadow-box" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ">Confirm Delete</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideDeleteModal}>
                                <span aria-hidden="true"></span>
                            </button>
                        </div>
                        <div className="modal-body text-dark">
                            <p>Are you sure you want to delete?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger mx-3" onClick={deleteContact}>Yes</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={hideDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />

            <h2>Team Member List</h2>
            <p>
                <Link title="Home Page" to={ROUTES.addteam.name}>Add Team Member</Link>
            </p>
            <table className="table">
                <tr>
                    <th>
                        Image
                    </th>
                    <th>
                        Name
                    </th>
                    <th>
                        profession
                    </th>
                    <th>
                        E-mail
                    </th>
                    <th>
                        Date
                    </th>
                    <th></th>
                </tr>

                {listOfTestimonial.map((value, key) => {
                    const createdAtDate = new Date(value.createdAt);
                    const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
                    return (
                        <tr key={key}>

                            <td>


                                {value.image ?
                                    (<img src={`https://bntserver.bntfoundation.com/${value.image}`} width="80px" style={{ padding: "0px", borderRadius:'40px' }}/>) :
                                    (<img src='Content/img/temp image.jpg' width="80px" style={{ padding: "0px" }} />)
                                }


                                {/* <img src='Content/img/temp image.jpg' width="50px" style={{ padding: "0px" }} /> */}

                            </td>
                            <td>
                                {value.name}
                            </td>
                            <td>
                                {value.profession}
                            </td>
                            <td>
                                {value.email}
                            </td>
                            <td>
                                {formattedDate}
                            </td>
                            <td >

                                <div className='text-primary d-inline onHover' onClick={() => navigate(`/editteam/${value.id}`)}>edit</div>

                                <div className='text-primary d-inline onHover' onClick={() => navigate(`/teamDetail/${value.id}`)}>Detail</div>

                                <div className='text-danger d-inline onHover' onClick={() => showDeleteModal(value.id)}>Delete</div>

                                {/* <Link
                                    class="d-inline"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                >
                                    Edit
                                </Link> */}
                                {/* <div className='text-danger d-inline' onClick={() => deleteContact(value.id)}>Delete</div> */}


                            </td>
                        </tr>

                    );
                })}

            </table>
        </div>

    )
}

export default TeamList;