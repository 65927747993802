import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ROUTES from './Routes.js';

function Navigation() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/********************************************************************* Home */}
          <Route path={ROUTES.layout.name} element={ROUTES.layout.component}>
            <Route
              path={ROUTES.index.name}
              element={ROUTES.index.component}
            ></Route>
            <Route
              path={ROUTES.footer.name}
              element={ROUTES.footer.component}
            ></Route>
            <Route
              path={ROUTES.navbar.name}
              element={ROUTES.navbar.component}
            ></Route>
            <Route
              path={ROUTES.about.name}
              element={ROUTES.about.component}
            ></Route>
            <Route
              path={ROUTES.contact.name}
              element={ROUTES.contact.component}
            ></Route>
            <Route
              path={ROUTES.donation.name}
              element={ROUTES.donation.component}
            ></Route>
            <Route
              path={ROUTES.blog.name}
              element={ROUTES.blog.component}
            ></Route>
            <Route
              path={ROUTES.blogdetail.name}
              element={ROUTES.blogdetail.component}
            ></Route>
            <Route
              path={ROUTES.gallery.name}
              element={ROUTES.gallery.component}
            ></Route>
            <Route
              path={ROUTES.team.name}
              element={ROUTES.team.component}
            ></Route>
            <Route
              path={ROUTES.gallerydetail.name}
              element={ROUTES.gallerydetail.component}
            ></Route>
            <Route
              path={ROUTES.contactconfirmation.name}
              element={ROUTES.contactconfirmation.component}
            ></Route>
            <Route
              path={ROUTES.logIn.name}
              element={ROUTES.logIn.component}
            ></Route>
            <Route
              path={ROUTES.register.name}
              element={ROUTES.register.component}
            ></Route>
            <Route
              path={ROUTES.privacypolicy.name}
              element={ROUTES.privacypolicy.component}
            ></Route>
            <Route
              path={ROUTES.gettheapp.name}
              element={ROUTES.gettheapp.component}
            ></Route>
          </Route>

          {/********************************************************************* Admin */}
          <Route
            path={ROUTES.adminlayout.name}
            element={ROUTES.adminlayout.component}
          >
            <Route
              path={ROUTES.home.name}
              element={ROUTES.home.component}
            ></Route>
            <Route
              path={ROUTES.contactlist.name}
              element={ROUTES.contactlist.component}
            ></Route>
            <Route
              path={ROUTES.contactdetail.name}
              element={ROUTES.contactdetail.component}
            ></Route>
            <Route
              path={ROUTES.addtestimonial.name}
              element={ROUTES.addtestimonial.component}
            ></Route>
            <Route
              path={ROUTES.testimoniallist.name}
              element={ROUTES.testimoniallist.component}
            ></Route>
            <Route
              path={ROUTES.edittestimonial.name}
              element={ROUTES.edittestimonial.component}
            ></Route>
            <Route
              path={ROUTES.testimonialdetail.name}
              element={ROUTES.testimonialdetail.component}
            ></Route>
            <Route
              path={ROUTES.gallerycategory.name}
              element={ROUTES.gallerycategory.component}
            ></Route>
            <Route
              path={ROUTES.addgallerycategory.name}
              element={ROUTES.addgallerycategory.component}
            ></Route>
            <Route
              path={ROUTES.gallerycategorydetail.name}
              element={ROUTES.gallerycategorydetail.component}
            ></Route>
            <Route
              path={ROUTES.editgallerycategory.name}
              element={ROUTES.editgallerycategory.component}
            ></Route>
            <Route
              path={ROUTES.gallerylist.name}
              element={ROUTES.gallerylist.component}
            ></Route>
            <Route
              path={ROUTES.addgallery.name}
              element={ROUTES.addgallery.component}
            ></Route>
            <Route
              path={ROUTES.editgallery.name}
              element={ROUTES.editgallery.component}
            ></Route>
            <Route
              path={ROUTES.bloglist.name}
              element={ROUTES.bloglist.component}
            ></Route>
            <Route
              path={ROUTES.addblog.name}
              element={ROUTES.addblog.component}
            ></Route>
            <Route
              path={ROUTES.editblog.name}
              element={ROUTES.editblog.component}
            ></Route>
            <Route
              path={ROUTES.blogdetaila.name}
              element={ROUTES.blogdetaila.component}
            ></Route>
            <Route
              path={ROUTES.addteam.name}
              element={ROUTES.addteam.component}
            ></Route>
            <Route
              path={ROUTES.teamlist.name}
              element={ROUTES.teamlist.component}
            ></Route>
            <Route
              path={ROUTES.teamdetail.name}
              element={ROUTES.teamdetail.component}
            ></Route>
            <Route
              path={ROUTES.payment.name}
              element={ROUTES.payment.component}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default Navigation;
