import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';


const ContactDetail = () => {
  let { id } = useParams();
  const [ContactObject, setContactObject] = useState({});
  useEffect(() => {
    axios.get(`https://bntserver.bntfoundation.com/ContactGetById.php?id=${id}`).then((response) => {
      setContactObject(response.data);
    })
  },[])
  let navigate = useNavigate();

  return (
    <div>
      <h2>Contact Detail</h2>
      <div>
        <dl className="dl-horizontal">
          <div className="AdminAlign">
            <dt>
              Name
            </dt>

            <dd>
              
              {ContactObject.length > 0 && (<p>{ContactObject[0].name}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>Email

            </dt>

            <dd>
            
              {ContactObject.length > 0 && (<p>{ContactObject[0].email}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>
              Subject
            </dt>

            <dd>

              {ContactObject.length > 0 && (<p>{ContactObject[0].subject}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>
              Massage
            </dt>

            <dd>

              {ContactObject.length > 0 && (<p>{ContactObject[0].message}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>
              Date
            </dt>

            <dd>
    
              {ContactObject.length > 0 && (<p>{ContactObject[0].createdAt}</p>)}


            </dd>
          </div>
        </dl>
        <Link to={ROUTES.contactlist.name}>Go Back</Link>
        {/* <div className='text-primary d-inline' onClick={()=>navigate(`/contactDetail/${ContactObject.id}`)}>Detail</div> */}

      </div>
    </div>
  )
}

export default ContactDetail;