import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';


function EditTestimonial() {

    let { id } = useParams();
    const [TestimonialObject, setTestimonialObject] = useState({});

    useEffect(() => {
        axios.get(`http://localhost:3001/BNT_Testimonial/byId/${id}`).then((response) => {
            setTestimonialObject(response.data);
        })
    }, [id])

    const onUpdate = async (data) => {
        try {
            const formData = new FormData();
            formData.append("id", data.id);
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("profession", data.profession);
            formData.append("message", data.message);
            formData.append("image", data.image);
            formData.append("rating", data.rating);
            formData.append("visibility", data.visibility);
            formData.append("priority", data.priority);

            await axios.put("http://localhost:3001/BNT_Testimonial", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            
            window.location.href = '/testimoniallist';

        } catch (error) {
            console.error("Error updating establishment:", error);
        }
    };


    return (
        <div>
            <h2>Edit Testimonial</h2>


            <Formik initialValues={TestimonialObject} onSubmit={onUpdate} enableReinitialize={true} >
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">


                            <div className="form-group">

                                <lable className="control-label col-md-2">Name</lable>
                                <div className="col-md-10">
                                    {/* @Html.EditorFor(model => model.Name, new { htmlAttributes = new { @class = "form-control" } }) */}

                                    {/* @Html.ValidationMessageFor(model => model.Name, "", new { @class = "text-danger" }) */}
                                    <ErrorMessage name="name" component="span" className='text-danger' />
                                    <Field name="name" className="form-control" placeholder="Name" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Email</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="email" component="span" className='text-danger' />
                                    <Field name="email" className="form-control" placeholder="Email" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Profession</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="profession" component="span" className='text-danger' />
                                    <Field name="profession" className="form-control" placeholder="profession" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Message</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="message" component="span" className='text-danger' />
                                    <Field name="message" className="form-control" placeholder="message" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Image</lable>

                                <div className="col-md-10">
                                    <input type="file" name="image" onChange={(event) => {
                                        setFieldValue("image", event.target.files[0]);
                                    }} />
                                    {/* <ErrorMessage name="image" component="span" className='text-danger' /> */}
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2 mb-4">Rating</lable>
                                <div><ErrorMessage name="rating" component="span" className='text-danger' /></div>
                                <div className="">
                                    <lable className=" col-md-2" htmlFor="1"><Field type='radio' name="rating" className="mx-1" value="1" />1 Star</lable>
                                    <lable className=" col-md-2" htmlFor="2"><Field type='radio' name="rating" className="mx-1" value="2" />2 Star</lable>
                                    <lable className=" col-md-2" htmlFor="3"><Field type='radio' name="rating" className="mx-1" value="3" />3 Star</lable>
                                    <lable className=" col-md-2" htmlFor="4"><Field type='radio' name="rating" className="mx-1" value="4" />4 Star</lable>
                                    <lable className=" col-md-2" htmlFor="5"><Field type='radio' name="rating" className="mx-1" value="5" />5 Star</lable>
                                </div>
                            </div>


                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="visibility" component="span" className='text-danger' />
                                    <Field as="select" name="visibility" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="true" label="true"></option>
                                        <option value="false" label="false"></option>
                                    </Field>
                                </div>
                            </div>
                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="priority" />
                                </div>
                            </div>


                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                                                type="submit"

                                                className="btn btn-primary"
                                            >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


            <div>

            </div>
        </div>
    )
}

export default EditTestimonial;