import React from 'react';
import { useEffect, useState } from 'react';
import axios from "axios";
import { Link, Route, useNavigate } from 'react-router-dom'
import ROUTES from '../../Nav/Routes';
function Blog() {
    const [listOfBlogs, setListOfBlogs] = useState([]);

    // const listOfBlogs = [
    //     {
    //         id: 1,
    //         name: 'BNT 1',
    //         priority: 1,
    //         isactive: true,
    //         writername: 'Baba Nanak Trust Foundation',
    //         writerpic: 'dbImages\\1706077160770-BreakingSAD.jpg',
    //         writerdesc: 'You can add writer description here',
    //         createdAt: '2024-01-09T10:41:18.000Z',
    //         updatedAt: '2024-01-24T06:19:20.000Z',
    //         blogtitle: 'A few weeks we learnt that a mother and her young daughter were fatally attacked outside a southeast Edmonton school.',
    //         blogdesc: 'A few weeks we learnt that a mother and her young daughter were fatally attacked outside a southeast Edmonton school. The loss of life at any time is difficult enough but the senseless attack on unsuspecting people, especially outside a school, is raising serious worries about safety in our neighbourhoods.',
    //         blogtitle2: 'Topic II',
    //         blogdesc2: 'On Monday, City of Edmonton’s Community After Incident Response (CAIR) team, in partnership with the Edmonton Police Service and a number of community organizations, held an information session at the Southwood Community League. The focus was on helping families and the community cope with trauma arising from the tragic event. BNT was once again invited to attend and provide mental health resources. A number of volunteers were present, along with our affiliated volunteers representing Breaking The Silence (BTS). A big thank you to all the volunteers 🙏.',
    //         blogtitle3: 'You can add third Topic here',
    //         blogdesc3: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
    //         blogpic: 'dbImages\\1706077160782-NewBNT14.jpg',
    //         blogurllink: '',
    //         sidedesc: 'You can add side description here',
    //         sidedesctitle: 'Side header',
    //         writerdesc: 'You can add writer description here'
    //     },
    //     {
    //         id: 2,
    //         name: 'BNT 2',
    //         priority: 3,
    //         isactive: true,
    //         writername: 'BNT',
    //         writerpic: 'dbImages\\1706077910332-Logo.jpg',
    //         createdAt: '2024-01-12T05:01:50.000Z',
    //         updatedAt: '2024-01-24T06:31:50.000Z',
    //         blogtitle: 'titleBNT is proud to be one of the lead partners with Edmonton Police Service (EPS)',
    //         blogdesc: 'This is in response to concerns by residents of SE Edmonton regarding growing violence incidents, especially related to the use of firearms. This will be of great interest to all the diverse communities that call SE Edmonton home.',
    //         blogtitle2: 'Topic II',
    //         blogdesc2: 'On Monday, City of Edmonton’s Community After Incident Response (CAIR) team, in partnership with the Edmonton Police Service and a number of community organizations, held an information session at the Southwood Community League. The focus was on helping families and the community cope with trauma arising from the tragic event. BNT was once again invited to attend and provide mental health resources. A number of volunteers were present, along with our affiliated volunteers representing Breaking The Silence (BTS). A big thank you to all the volunteers',
    //         blogtitle3: 'subhead, the indent is superfluous and can therefore be omitted',
    //         blogdesc3: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
    //         blogpic: 'dbImages\\1706077910344-tempimg3.jpg',
    //         blogurllink: '',
    //         sidedesc: '',
    //         sidedesctitle: '',
    //         writerdesc: ''
    //     },
    //     {
    //         id: 4,
    //         name: 'On December 4th BNT',
    //         priority: 5,
    //         isactive: true,
    //         writername: 'Baba Nanak Trust Foundation',
    //         writerpic: 'dbImages\\1706077502629-favicon.png',
    //         createdAt: '2024-01-22T11:17:54.000Z',
    //         updatedAt: '2024-01-24T06:36:04.000Z',
    //         blogtitle: 'On December 4th BNT',
    //         blogdesc: 'Volunteer Bhuvinder Gill made a presentation on Sikhism and Punjabi culture to an interfaith audience at Meridian Banquet Hall. The program was organized by The Shaama Centre as part of their "Get to Know Your Neighbour" series of interactive events.',
    //         blogtitle2: 'Topic II',
    //         blogdesc2: 'On Monday, City of Edmonton’s Community After Incident Response (CAIR) team, in partnership with the Edmonton Police Service and a number of community organizations, held an information session at the Southwood Community League. The focus was on helping families and the community cope with trauma arising from the tragic event. BNT was once again invited to attend and provide mental health resources. A number of volunteers were present, along with our affiliated volunteers representing Breaking The Silence (BTS). A big thank you to all the volunteers',
    //         blogtitle3: 'You can add third Topic here',
    //         blogdesc3: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
    //         blogpic: 'dbImages\\1706077502645-NewBNT17.jpg',
    //         blogurllink: '',
    //         sidedesc: '',
    //         sidedesctitle: '',
    //         writerdesc: ''
    //     }
       
    // ];


    let navigate = useNavigate();
    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/BlogActiveGet.php").then((response) => {
            setListOfBlogs(response.data);
            console.log(response.data);
        })
    }, [])
    const handleClickTop = () => {
        window.scrollTo(0, 330);
    };
    const readMore = (id) => {
        handleClickTop();
        navigate(`/BlogDetail/${id}`);
    }
    return (
        <div>

            <div class="hero page-inner overlay"
                style={{ backgroundImage: `url('../../Content/img/slide/BNT5.jpg')` }}>
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-9 text-center mt-5">
                            <h1 class="heading" data-aos="fade-up">Blog</h1>

                            <nav aria-label="breadcrumb"
                                data-aos="fade-up"
                                data-aos-delay="200">
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gray-back">

                <div className="container py-5">
                    <div className="row justify-content-center text-center py-3">
                        <div className="col-lg-5">
                            <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                Latest Blogs
                            </h2>
                            <h6 data-aos="fade-up" data-aos-delay="100">
                                Stay informed about the latest mental health research
                            </h6>
                        </div>
                    </div>
                    <div className="row ">

                        {listOfBlogs.map((value, key) => {
                            const createdAtDate = new Date(value.createdAt);
                            const options = { day: 'numeric' };
                            const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);

                            const createdAtDate1 = new Date(value.createdAt);
                            const options1 = { month: 'long' };
                            const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);

                            const createdAtDate2 = new Date(value.createdAt);
                            const options2 = { year: 'numeric' };
                            const formattedDate2 = createdAtDate2.toLocaleDateString('en-CA', options2);
                            return (
                                <div className="col-12 col-md-6 col-lg-4 my-4" data-aos="fade-up" key={key}>
                                    <div className="box-news">
                                        <div onClick={() => readMore(value.id)} className="link-acent">


                                            <div class="imageHolder" style={{
                                                backgroundImage: `url(https://bntserver.bntfoundation.com/${value.blogpic.replace(/\\/g, '/')})`
                                            }}></div>

                                        </div>
                                        <div className="NewsDContainer">
                                            <div className="newsSidePanel">
                                                {/* @{
                                        var date = Convert.ToDateTime(item.Date);

                                    }
                                    <span>@date.ToString("dd")</span>
                                    <p>@date.ToString("MMMM")</p>
                                    <p>@date.ToString("yyyy")</p> */}
                                                <span>{formattedDate}</span>
                                                <p>{formattedDate1}</p>
                                                <p>{formattedDate2}</p>
                                            </div>
                                            <div className="newsContent">

                                                <div onClick={() => readMore(value.id)} className="link-acent">

                                                    <h6 className="ola">{value.blogtitle}
                                                    </h6>
                                                </div>

                                                {/* <script>
                                        var text1 = "";
                                        if (item.NewsDesc == null)
                                        {
                                            text1 = "";
                                        }
                                        else if (item.NewsDesc.Length >= 130)
                                        {
                                            text1 = item.NewsDesc.Substring(0, 130) + "...";
                                        }
                                        else
                                        {
                                            text1 = item.NewsDesc;
                                        }
                                    </script> */}
                                                <p>
                                                    {value.blogdesc.length > 50
                                                        ? `${value.blogdesc.substring(0, 100)}...`
                                                        : value.blogdesc}
                                                </p>
                                                <div onClick={() => readMore(value.id)} className="link-acent">
                                                    Read more</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}



                    </div>
                </div>
            </div>

        </div>
    )
}

export default Blog;