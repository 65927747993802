import React, { useState } from 'react';
import { Link, Outlet, Route } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';

const ToggleB = () => {
  let sidebar = document.querySelector('.Sideheader');
  let AdminB = document.querySelector('.bodyContainer');
  let name = document.querySelectorAll('.name');

  sidebar.classList.toggle('Sideheader1');
  AdminB.classList.toggle('bodyContainer1');
  name.forEach((name) => {
    name.classList.toggle('name1');
  });
};

function AdminLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const sidebarClass = isSidebarOpen ? 'Sideheader1' : 'Sideheader';
  const adminBClass = isSidebarOpen ? 'bodyContainer1' : 'bodyContainer';
  const nameClass = isSidebarOpen ? 'name1' : 'name';
  return (
    <div>
      <div className="AdminMContainer">
        <div className="Sideheader">
          <button className="btn2" onClick={ToggleB}>
            <i className="fa-solid fa-bars-staggered"></i>
          </button>

          <ul>
            <li>
              <Link className="anchor" title="Home Page" to={ROUTES.home.name}>
                <i className="fa-solid fa-house-chimney "></i>{' '}
                <span className="name">Home</span>{' '}
              </Link>
            </li>
            <li>
              <Link
                className="anchor"
                title="Contact List"
                to={ROUTES.contactlist.name}
              >
                <i className="fa-solid fa-envelope-open-text"></i>{' '}
                <span className="name">Contact List</span>
              </Link>
            </li>

            <li>
              <Link
                className="anchor"
                title="Blog List"
                to={ROUTES.bloglist.name}
              >
                <i className="fa-solid fa-newspaper"></i>{' '}
                <span className="name">Blog List</span>
              </Link>
            </li>
            <li>
              <Link
                className="anchor"
                title="Team Member List"
                to={ROUTES.teamlist.name}
              >
                <i className="fa-solid fa-users"></i>
                <span className="name">Member List</span>{' '}
              </Link>
            </li>
            <li>
              <Link
                className="anchor"
                title="Gallery Categories List"
                to={ROUTES.gallerycategory.name}
              >
                <i className="fa-solid fa-photo-film"></i>
                <span className="name">Gallery Event</span>{' '}
              </Link>
            </li>
            <li>
              <Link
                className="anchor"
                title="Gallery List"
                to={ROUTES.gallerylist.name}
              >
                <i className="fa-solid fa-image"></i>
                <span className="name">Gallery List</span>{' '}
              </Link>
            </li>
            {/* <li><a className="anchor" title="Feedback List" href="@Url.Action(" Contactlist","Admin")"><i className="fa-solid fa-envelope-open-text"></i> <span className="name">Contact list</span> </a></li > */}
            <li>
              <Link
                className="anchor"
                title="TestimonialList"
                to={ROUTES.testimoniallist.name}
              >
                <i className="fa-solid fa-comments"></i>{' '}
                <span className="name">Testimonial</span>
              </Link>
            </li>
            {/* <li><a className="anchor" title="Booking List" href="@Url.Action(" bookingList","Admin")"><i className="fa-regular fa-calendar-check"></i> <span className="name">Booking</span> </a></li > */}
            <li>
              <Link
                className="anchor"
                title="Donation"
                to={ROUTES.payment.name}
              >
                <i class="fa-solid fa-money-bill"></i>
                <span className="name">Donation</span>{' '}
              </Link>
            </li>
            <li>
              <Link
                className="anchor"
                title="Log Off from Admin panel & Go to Home Page"
                to={ROUTES.index.name}
              >
                <i className="fa-solid fa-power-off"></i>{' '}
                <span className="name">Log Out</span>{' '}
              </Link>
            </li>

            <li></li>
          </ul>
        </div>
        <div className="bodyContainer">
          <div className="AdminContainer">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
