// import '../../Client/public/Content/js/owl.carousel.js';
import { React, useEffect, useState, useRef } from 'react';
import { Link, Outlet, Route, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import axios from "axios";
import Slider from "react-slick";
// import "./CSS/slick.css";

function Index() {
    //for slider
    const sliderRef = useRef(null);
    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };
    //testimonial list state
    const [listOfTestimonial, setListOfTestimonial] = useState([]);
    const [listOfBlogs, setListOfBlogs] = useState([]);
    const [listOfGallery, setListOfGallery] = useState([]);
    const [listOfTeam, setListOfTeam] = useState([]);



    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/TestimonialActiveGet.php").then((response) => {
            setListOfTestimonial(response.data);
        })
    }, [])
    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/BlogActiveGet3.php").then((response) => {
            setListOfBlogs(response.data);
            
        })
    }, [])
    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/TeamActiveGet3.php").then((response) => {
            setListOfTeam(response.data);
            
        })
    }, [])
    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/GalleryCategoriesActiveGet3.php").then((response) => {
            setListOfGallery(response.data);
            
        })
    }, [])

    const handleClickTop = () => {
        window.scrollTo(0, 300);
    };


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,

        slidesToShow: 3,

        autoplay: true,
        centerPadding: '50px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 668,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    let navigate = useNavigate();
    const readMore = (id) => {
        handleClickTop();
        navigate(`/BlogDetail/${id}`);
    }
    const readMore1 = (id) => {
        handleClickTop();
        navigate(`/GalleryDetail/${id}`);
    }
    return (

        <div>

            <div className="col-md-12 m-0 p-0 position-relative">
                <div className="sliderContainer">
                    <div className="sliderBox1 ">
                        <div className="slideImage1 mb-4 " style={{ backgroundImage: ("url('Content/img/slide/BNT9.jpg')") }}>
                        </div>

                        <div className="slideImage1 mb-4 " style={{ backgroundImage: ("url('Content/img/NewBNT14.jpg')") }}>
                        </div>

                        <div className="slideImage1 mb-4 " style={{ backgroundImage: ("url('Content/img/NewBNT192.jpg')") }}>
                        </div>
                        <div className="slideImage1 mb-4 " style={{ backgroundImage: ("url('Content/img/slide/BNT9.jpg')") }}>
                        </div>
                    </div>
                </div>
                <div className='overIt'>
                    <div className="col-lg-9 text-center">
                        {/* <h5 className="beforeHeading" data-aos="fade-up">Welcome to</h5> */}

                        <h1 className="bosterHeading" data-aos="fade-up" data-aos-delay="300">
                            Baba Nanak Trust Foundation
                        </h1>
                        <h5 className="text-gray" data-aos="fade-up" data-aos-delay="600">
                            Easiest way to find peace
                        </h5>
                    </div>
                </div>
            </div>








            {/*"""""""""""""""""""""""""""""""""""""""""""""""""""""" own */}
            {/* <div className="hero">
                <div className="hero-slide">
                    <div className="img overlay"
                        style={{ backgroundImage: ` url('../../Content/img/slide/BNT5.jpg')` }}></div>
                    <div className="img overlay"
                        style={{ backgroundImage: ` url('../../Content/img/slide/BNT9.jpg')` }}></div>
                    <div className="img overlay"
                        style={{ backgroundImage: ` url('../../Content/img/slide/BNT7.jpg')` }}></div>

                </div>

                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9 text-center">
                            <h5 className="beforeHeading" data-aos="fade-up">Welcome to</h5>
                            <h1 className="bosterHeading" data-aos="fade-up" data-aos-delay="300">
                                Baba Nanak Trust Foundation
                            </h1>
                            <h5 className="text-gray" data-aos="fade-up" data-aos-delay="600">
                                Easiest way to find peace
                            </h5>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="container">
                <div className="row align-items-center my-5">
                    <div className="col-sm-12 col-lg-6 pr-4 my-4">
                        <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                            About Us
                        </h2>
                        <h5 className="text-secondary mb-3" data-aos="fade-up">
                            Baba Nanak Trust, Non-Profit Organization
                        </h5>
                        <p className="text-gray-dark" data-aos="fade-up" data-aos-delay="300">
                            Baba Nanak Trust Foundation, BNT, is an Edmonton-based community services society established in 2019 to meet the special needs of socially and psychologically vulnerable South Asians. BNT (pronounced Bay-N-Tee, a humble plea or an emotional prayer) is a registered Canadian Charitable Society that is permitted to issue tax-deductible receipts for eligible donations.
                        </p>
                        <p data-aos="fade-up" data-aos-delay="500">
                            <Link className="btn btn-secondary text-white py-3 px-4" to={ROUTES.about.name} onClick={handleClickTop}>Learn More</Link>

                        </p>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="imageContainer">
                            <img src="Content/img/BNT 7.jpg" />
                        </div>
                    </div>
                </div>
            </div>


            <section className="features-1">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-6 col-lg-3" data-aos="fade-up">
                            <div className="box-feature">
                                <div className="ekonkar">
                                    <img src="Content/img/ekOAnkar.png" alt="Alternate Text" />
                                </div>
                                <h3 className="mb-3">Mental Wellness Tips</h3>
                                <p>
                                    Explore expert tips and guidance to boost your mental well-being.
                                </p>
                                <p><Link className="learn-more" to={ROUTES.about.name} onClick={handleClickTop} >Learn More</Link></p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="200">
                            <div className="box-feature">
                                <div className="ekonkar">
                                    <img src="Content/img/ekOAnkar.png" alt="Alternate Text" />
                                </div>
                                <h3 className="mb-3">Community Support</h3>
                                <p>
                                    Connect with others on a similar journey to share and support.
                                </p>
                                <p><Link className="learn-more" to={ROUTES.about.name} onClick={handleClickTop}>Learn More</Link></p>

                            </div>
                        </div>
                        <div className="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="400">
                            <div className="box-feature">
                                <div className="ekonkar">
                                    <img src="Content/img/ekOAnkar.png" alt="Alternate Text" />
                                </div>
                                <h3 className="mb-3">Counseling Resources</h3>
                                <p>
                                    Access professional counseling and therapy services.
                                </p>
                                <p><Link className="learn-more" to={ROUTES.about.name} onClick={handleClickTop}>Learn More</Link></p>

                            </div>
                        </div>
                        <div className="col-6 col-lg-3" data-aos="fade-up" data-aos-delay="600">
                            <div className="box-feature">
                                <div className="ekonkar">
                                    <img src="Content/img/ekOAnkar.png" alt="Alternate Text" />
                                </div>
                                <h3 className="mb-3">Inspiration Daily</h3>
                                <p>
                                    Find daily motivation and positivity to brighten your day.
                                </p>
                                <p><Link className="learn-more" to={ROUTES.about.name} onClick={handleClickTop}>Learn More</Link></p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div className="section section-4 bg-light">
                <div className="container">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-lg-5">
                            <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                Our Mission
                            </h2>
                            <h5 className="text-secondary" data-aos="fade-up" data-aos-delay="100">
                                Enhancing lives of individuals who are
                            </h5>
                        </div>
                    </div>
                    <div className="row justify-content-between mb-5">
                        <div className="col-lg-7 mb-5 mb-lg-0 order-lg-2">
                            <div className="img-about dots" data-aos="fade-left" data-aos-delay="500">
                                <img src="Content/img/NewBNT4.jpg" alt="Image" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex feature-h" data-aos="fade-right">
                                <span className="wrap-icon me-3">
                                    <span className="icon-home2"></span>
                                </span>
                                <div className="feature-text">
                                    <h3 className="heading">Isolated</h3>
                                    <p className="text-gray-dark">
                                        We strive to build a community where no one feels alone. We provide a supportive network to combat isolation and foster meaningful connections
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex feature-h" data-aos="fade-right" data-aos-delay="100">
                                <span className="wrap-icon me-3 d-flex justify-content-center align-items-center">
                                    <span>
                                        <i className="fa-solid fa-face-sad-tear"></i>
                                    </span>
                                </span>
                                <div className="feature-text">
                                    <h3 className="heading">Abused</h3>
                                    <p className="text-gray-dark">
                                        helping survivors of abuse find their strength, heal, and rebuild their lives with dignity and resilience
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex feature-h" data-aos="fade-right" data-aos-delay="200">
                                <span className="wrap-icon me-3 d-flex justify-content-center align-items-center">
                                    <i className="fa-solid fa-heart-crack"></i>
                                </span>
                                <div className="feature-text">
                                    <h3 className="heading">Depressed</h3>
                                    <p className="text-gray-dark">
                                        bring hope and healing to those struggling with depression.
                                    </p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="300">
                                <p>
                                    <Link className="btn btn-secondary text-white py-3 px-4" to={ROUTES.about.name} onClick={handleClickTop}>Read More</Link>


                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>





            <div className="gray-back">

                <div className="container py-5">
                    <div className="row justify-content-center text-center py-3">
                        <div className="col-lg-5">
                            <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                Latest Blog
                            </h2>
                            <h6 data-aos="fade-up" data-aos-delay="100">
                                Stay informed about the latest mental health research
                            </h6>
                        </div>
                    </div>
                    <div className="row ">




                        {listOfBlogs.map((value, key) => {
                            const createdAtDate = new Date(value.createdAt);
                            const options = { day: 'numeric' };
                            const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);

                            const createdAtDate1 = new Date(value.createdAt);
                            const options1 = { month: 'long' };
                            const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);

                            const createdAtDate2 = new Date(value.createdAt);
                            const options2 = { year: 'numeric' };
                            const formattedDate2 = createdAtDate2.toLocaleDateString('en-CA', options2);
                            return (
                                <div className="col-12 col-md-6 col-lg-4 my-4" data-aos="fade-up" key={key}>
                                    <div className="box-news">
                                        <div onClick={() => readMore(value.id)} className="link-acent">


                                            <div class="imageHolder" style={{
                                                backgroundImage: `url(https://bntserver.bntfoundation.com/${value.blogpic.replace(/\\/g, '/')})`
                                            }}></div>

                                        </div>
                                        <div className="NewsDContainer">
                                            <div className="newsSidePanel">
                                                {/* @{
                                        var date = Convert.ToDateTime(item.Date);

                                    }
                                    <span>@date.ToString("dd")</span>
                                    <p>@date.ToString("MMMM")</p>
                                    <p>@date.ToString("yyyy")</p> */}
                                                <span>{formattedDate}</span>
                                                <p>{formattedDate1}</p>
                                                <p>{formattedDate2}</p>
                                            </div>
                                            <div className="newsContent">

                                                <div onClick={() => readMore(value.id)} className="link-acent">

                                                    <h6 className="ola">{value.blogtitle}
                                                    </h6>
                                                </div>

                                                {/* <script>
                                        var text1 = "";
                                        if (item.NewsDesc == null)
                                        {
                                            text1 = "";
                                        }
                                        else if (item.NewsDesc.Length >= 130)
                                        {
                                            text1 = item.NewsDesc.Substring(0, 130) + "...";
                                        }
                                        else
                                        {
                                            text1 = item.NewsDesc;
                                        }
                                    </script> */}
                                                <p>
                                                    {value.blogdesc.length > 50
                                                        ? `${value.blogdesc.substring(0, 100)}...`
                                                        : value.blogdesc}
                                                </p>
                                                <div onClick={() => readMore(value.id)} className="link-acent">
                                                    Read more</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                        <p className="col-12 text-center mt-4" data-aos="fade-up">
                            <Link className="btn btn-secondary text-white py-3 px-4" to={ROUTES.blog.name} onClick={handleClickTop}>Discover More</Link>


                        </p>

                    </div>
                </div>
            </div>


            <div className="section section-5 bg-light ">
                <div className="container pb-4 " data-aos="fade-up">
                    <div className="row justify-content-center text-center mb-5" data-aos="fade-up">
                        <div className="col-lg-6 mb-5">
                            <h2 className="font-weight-bold heading text-primary mb-4">
                                Our Team
                            </h2>
                            <h6 className="text-black-50">
                                Get to know the experts behind our mission. Meet our passionate team dedicated to your mental health and mental wealth journey.

                            </h6>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up">



                        {listOfTeam.map((value, key) => {
                            const rating = value.rating; // Assuming rating is the key in your database

                            // Create an array with the number of stars equal to the rating
                            const stars = Array.from({ length: rating }, (_, i) => (
                                <span key={i} className="icon-star text-warning pr-1"></span>
                            ));

                            // Fill the remaining stars with black-50
                            const remainingStars = Array.from({ length: 5 - rating }, (_, i) => (
                                <span key={rating + i} className="icon-star text-black-50 pr-1"></span>
                            ));

                            return (
                                <div className="col-sm-6 col-md-6 col-lg-4 my-6 " key={key}>
                                    <div className="h-100 person">
                                        <div className="personImgs">




                                            {value.image ?
                                                (<img className="img-fluid" src={`https://bntserver.bntfoundation.com/${value.image}`} width="100%" style={{ padding: "0px", borderRadius: '40px' }} />) :
                                                (<img className="img-fluid" src='Content/img/temp image.jpg' width="100%" style={{ padding: "0px" }} />)
                                            }
                                        </div>

                                        <div className="person-contents pt-0">
                                            <h2 className="mb-0">{value.name}</h2>
                                            <span className="meta d-block mt-1 mb-2">{value.profession}</span>

                                            <p className="text-Greey">
                                                {value.bio}
                                            </p>


                                            <ul className="social list-unstyled list-inline dark-hover">
                                                <li className="list-inline-item">
                                                    <a href={`mailto:${value.email}`}><span className="icon-envelope"></span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                )
                        })}



                    </div>
                    <p className="col-12 text-center mt-5" data-aos="fade-up" data-aos-delay="50">
                        <Link className="btn btn-secondary text-white py-3 px-4" to={ROUTES.team.name} onClick={handleClickTop}>See All Members</Link>


                    </p>
                </div>
            </div>




            <div className="subgray-back">
                <div className="container pt-5">
                    <div className="row py-5">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-lg-8">
                                <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                    Explore Our Gallery
                                </h2>
                                <h5 className="text-secondary" data-aos="fade-up" data-aos-delay="50">
                                    Baba Nanak Trust, Non-Profit Organization
                                </h5>
                            </div>
                        </div>

                        {listOfGallery.map((value, key) => {
                            const createdAtDate = new Date(value.createdAt);
                            const options = { day: 'numeric', month: 'long', year: 'numeric' };
                            const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
                            return (
                                <div className="col-12 col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="200" key={key}>
                                    <div className="AdminGal1">

                                        <div onClick={() => readMore1(value.id)} className="link-acent">
                                            {/* <div class="imageCover2 " style={{
                                                backgroundImage: `url(./${encodeURIComponent(`https://bntserver.bntfoundation.com/${value.image}`.replace(/\\/g, '/'))})`
                                            }}></div> */}



                                            <div className="imageCover2" style={{
                                                backgroundImage: `url(https://bntserver.bntfoundation.com/${value.image.replace(/\\/g, '/')})`
                                            }}></div>

                                        </div>

                                        <div className="textCase1">

                                            <h4 className="text-black my-3">

                                                <div onClick={() => readMore1(value.id)} className="link-acent">
                                                    {/* @Html.DisplayFor(modelItem => item.Category) */}
                                                    {value.category}
                                                </div>

                                            </h4>
                                            {/* @{
                                        var date = Convert.ToDateTime(item.Date);
                                    } */}
                                            <p className="text-success">
                                                {/* @date.ToString("dd MMM yyy") */}
                                                {/* {formattedDate} */}

                                            </p>
                                            {/* @{
                                        var text = "";
                                        if (item.Description == null)
                                        {
                                            text = "";
                                        }
                                        else if (item.Description.Length >= 200)
                                        {
                                            text = item.Description.Substring(0, 200) + "...";
                                        }
                                        else
                                        {
                                            text = item.Description;
                                        }
                                    } */}
                                            <p>
                                                {value.description}
                                            </p>
                                            <p className="text-primary">
                                                <div onClick={() => readMore1(value.id)} className="link-acent">
                                                    View Gallery
                                                </div>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <p className="col-12 text-center mt-4" data-aos="fade-up">
                            <Link className="btn btn-secondary text-white py-3 px-4" to={ROUTES.gallery.name} onClick={handleClickTop}>Discover All</Link>


                        </p>
                    </div>
                </div>
            </div>






            {listOfTestimonial.length > 1 && (
            <div className='section sec-testimonials'>
                <div className='container'>
                    <div className='row mb-5 align-items-center'>
                        <div className='col-md-12'>
                            <h2 className="font-weight-bold heading text-primary mb-4 mb-md-0">
                                Connected People Says
                            </h2>
                        </div>
                    </div>

                    {/* own slider************************************************* */}
                    <div id="testimonial_area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">

                                    <Slider  {...settings} ref={sliderRef}>
                                        {listOfTestimonial.map((value, index) => {
                                            const rating = value.rating; // Assuming rating is the key in your database

                                            // Create an array with the number of stars equal to the rating
                                            const stars = Array.from({ length: rating }, (_, i) => (
                                                <span key={i} className="icon-star text-warning pr-1"></span>
                                            ));

                                            // Fill the remaining stars with black-50
                                            const remainingStars = Array.from({ length: 5 - rating }, (_, i) => (
                                                <span key={rating + i} className="icon-star text-black-50 pr-1"></span>
                                            ));

                                            return (


                                                <div key={index} className="box-area testimonial px-2">
                                                    <div className="img-area">

                                                        {/* <div className="testimonialImg mb-4" style={{ backgroundImage: ("url('Content/img/temp image.jpg')") }}>
                                                </div> */}

                                                        {value.image ?
                                                            <div className="testimonialImg mb-4" style={{ backgroundImage: `url(https://bntserver.bntfoundation.com/${value.image.replace(/\\/g, '/')})` }}></div> :

                                                            (<div className="testimonialImg mb-4" style={{ backgroundImage: ("url('Content/img/temp image.jpg')") }}></div>)
                                                        }



                                                    </div>

                                                    <div className="rate">
                                                        {stars}
                                                        {remainingStars}
                                                    </div>
                                                    <h3 className="h5 text-primary mb-4">{value.name}</h3>
                                                    <p className="text-black-50">{value.email}</p>

                                                    <blockquote>
                                                        <p>
                                                            &ldquo;{value.message}&rdquo;
                                                        </p>
                                                    </blockquote>
                                                    <p className="text-black-50">{value.profession}</p>

                                                </div>
                                            )
                                        })}

                                    </Slider>
                                </div>
                                <div className="mt-5 w-100">
                                    <div className="col-md-12 text-center py-5">
                                        <div id="testimonial-nav" data-aos="fade-up" data-aos-delay="400">
                                            <span className="prev py-3 btn mx-2 prevBtn" data-controls="prev" onClick={handlePrev}><i className="fa-solid fa-arrow-left"></i></span>
                                            <span className="next py-3 btn mx-2 nextBtn" data-controls="next" onClick={handleNext}><i className="fa-solid fa-arrow-right"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

            {/* own slider************************************************* */}




        </div>
    )
}

export default Index