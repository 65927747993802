import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GalleryCategory = () => {
    const [listOfGalleryCategory, setListOfGalleryCategory] = useState([]);
    // const [editValues, setEditValues] = useState(null);
    const [currentId, setCurrentId] = useState("");
    const notifyDelete = () => toast("Item Deleted Successfully!", {
        hideProgressBar: false,
        pauseOnHover: false,
        hideProgressBar: true,
        style: {
            style: {
                color: '#008000',
            }
        }
    });

    useEffect(() => {
        fetchGalleryData();
    }, [currentId]);
    const fetchGalleryData = async () => {
        try {
            // const response = await fetch('http://csm.3scoders.in/get.php');
               const response = await fetch('http://bntserver.bntfoundation.com/GalleryCategoriesAllGet.php');
            const data = await response.json();
            console.log(data);
            setListOfGalleryCategory(data);
        } catch (error) {
            console.error('Error fetching gallery data:', error);
        }
    };
    
    let navigate = useNavigate();
    //for delete request
    // const deleteContact = async() => {
    //     axios.delete(`https://bntserver.bntfoundation.com/GalleryCategoriesDelete.php?id=${currentId}`).then(() => {
    //         // listOfContacts(response.data);
    //         setListOfGalleryCategory(listOfGalleryCategory.filter(function (item) {
    //             return item.id !== currentId;
    //         }))
    //     }).then(() => {
    //         hideDeleteModal();
    //         notifyDelete();
    //     })

    // }
    const deleteContact = async () => {
       
         // Send a DELETE request to your PHP file
         fetch(`https://bntserver.bntfoundation.com/GalleryCategoriesDelete.php?id=${currentId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
          
        })
        .then(response => response.json())
        .then(data => {
            console.log(data); // Log the response from the server
            // You can update state or show a message based on the response
        }).then(() => {
            // listOfContacts(response.data);
            setListOfGalleryCategory(listOfGalleryCategory.filter(function (item) {
                return item.id !== currentId;
            }))
        }).then(() => {
            hideDeleteModal();
            notifyDelete();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };
    
    // const handleEditClick = (value) => {
    //     setEditValues(value);
    // };

    const showDeleteModal = (testimonialId) => {
        document.getElementById("deleteModal").style.display = "block";
        setCurrentId(testimonialId);

    }
    const hideDeleteModal = () => {
        document.getElementById("deleteModal").style.display = "none";
    }

    return(
<div className='w-100'>
            <div className="modal " id='deleteModal'>
                <div className="modal-dialog shadow-box" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ">Confirm Delete</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideDeleteModal}>
                                <span aria-hidden="true"></span>
                            </button>
                        </div>
                        <div className="modal-body text-dark">
                            <p>Are you sure you want to delete?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger mx-3" onClick={deleteContact}>Yes</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={hideDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />

            <h2>Gallery Event List</h2>
            <p>
                <Link title="Home Page" to={ROUTES.addgallerycategory.name}>Add Gallery Event</Link>
            </p>
            <table className="table">
                <tr>
                    <th>
                        Image
                    </th>
                    <th>
                       Event Name
                    </th>
                    <th>
                        Date
                    </th>
                    
                    <th></th>
                </tr>

                {listOfGalleryCategory.map((value, key) => {
                    const createdAtDate = new Date(value.createdAt);
                    const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
                    return (
                        <tr key={key}>

                            <td>


                                
                                    <img src={`https://bntserver.bntfoundation.com/${value.image}`} width="100px" style={{ padding: "0px", }}/>
                                


                                

                            </td>
                            <td>
                                {value.category}
                               
                            </td>
                            
                            <td>
                                {formattedDate}
                            </td>
                            <td >

                                <div className='text-primary d-inline onHover' onClick={() => navigate(`/editgallerycategory/${value.id}`)}>Edit</div>

                                <div className='text-primary d-inline onHover' onClick={() => navigate(`/gallerycategoryDetail/${value.id}`)}>Detail</div>

                                <div className='text-danger d-inline onHover' onClick={() => showDeleteModal(value.id)}>Delete</div>

                                {/* <Link
                                    class="d-inline"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                >
                                    Edit
                                </Link> */}
                                {/* <div className='text-danger d-inline' onClick={() => deleteContact(value.id)}>Delete</div> */}


                            </td>
                        </tr>

                    );
                })}

            </table>
        </div>

    )
}

export default GalleryCategory;