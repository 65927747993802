import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactList = () => {
  // This is for get data list
  const [listOfContacts, setListOfContacts] = useState([]);

  const [currentId, setCurrentId] = useState("");
  const notifyDelete = () => toast("Item Deleted Successfully!", {
    hideProgressBar: false,
    pauseOnHover: false,
    hideProgressBar: true,
    style: {
      color: '#008000',
    }
  });


  useEffect(() => {
    axios.get("https://bntserver.bntfoundation.com/ContactGet.php").then((response) => {
      setListOfContacts(response.data);


    })
  }, [])
  let navigate = useNavigate();


  //for delete request
  const deleteContact = () => {
    axios.delete(`https://bntserver.bntfoundation.com/ContactDelete.php?id=${currentId}`).then(() => {
      // listOfContacts(response.data);
      setListOfContacts(listOfContacts.filter(function (item) {
        return item.id !== currentId;
      }))
    }).then(() => {
      hideDeleteModal();
      notifyDelete();
    })

  }

  const showDeleteModal = (contactId) => {
    document.getElementById("deleteModal").style.display = "block";
    setCurrentId(contactId);
  }

  const hideDeleteModal = () => {
    document.getElementById("deleteModal").style.display = "none";
  }
  
  return (
    <div className='w-100'>
      <div className="modal " id='deleteModal'>
        <div className="modal-dialog shadow-box" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title ">Confirm Delete</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideDeleteModal}>
                <span aria-hidden="true"></span>
              </button>
            </div>
            <div className="modal-body text-dark">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger mx-3" onClick={deleteContact}>Yes</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={hideDeleteModal}>No</button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      <h2>Contact List</h2>

      <table className="table">
        
          <tr>
            <th>
              Name
            </th>
            <th>
              E-mail
            </th>
            <th>
              Date
            </th>
            <th></th>
          </tr>
        
        
        {listOfContacts.map((value, key) => {
          const createdAtDate = new Date(value.createdAt);
          const options = { day: 'numeric', month: 'long', year: 'numeric' };
          const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
          return (
            <tr key={key}>

              <td>
                {value.name}
              </td>
              <td>
                {value.email}
              </td>
              <td>

                {formattedDate}


              </td>
              <td >

                <div className='text-primary d-inline onHover' onClick={() => navigate(`/contactDetail/${value.id}`)}>Detail</div>

                <div className='text-danger d-inline onHover' onClick={() => showDeleteModal(value.id)}>Delete</div>


                {/* <div className='text-danger d-inline' onClick={() => deleteContact(value.id)}>Delete</div> */}


              </td>
            </tr>

          );
        })}
        
      </table>
    </div>

  )
}

export default ContactList;