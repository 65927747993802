import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';



const GalleryCategoryDetail = () => {

    let { id } = useParams();
    const [GalleryCategoryObject, setGalleryCategoryObject] = useState({});
    const [formattedCDate, setFormattedCDate] = useState("");
    const [formattedEDate, setFormattedEDate] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://bntserver.bntfoundation.com/GalleryCategoryByIdGet.php?id=${id}`);
                const data = await response.json();

                setGalleryCategoryObject(data);
                
                const createdAtDate1 = new Date(GalleryCategoryObject[0].createdAt);
                const options1 = { day: 'numeric', month: 'long', year: 'numeric' };
                const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);
                setFormattedCDate(formattedDate1);

                const createdAtDate = new Date(GalleryCategoryObject[0].updatedAt);
                const options = { day: 'numeric', month: 'long', year: 'numeric' };
                const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
                setFormattedEDate(formattedDate);
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [formattedCDate, id])


      let navigate = useNavigate();
  
    return (
        <div>
            <h2>Contact Detail</h2>
            <div>
                <dl className="dl-horizontal">
                    <div className="AdminAlign">
                        <dd>
                        
                        
                        {GalleryCategoryObject.length > 0 && (<img src={`https://bntserver.bntfoundation.com/${GalleryCategoryObject[0].image}`} width={"400px"} />)}
                        </dd>
                    </div>
                    <div className="AdminAlign">
                        <dt>
                            Event Name
                        </dt>

                        <dd>
                        {GalleryCategoryObject.length > 0 && (<p>{GalleryCategoryObject[0].category}</p>)}
                        
                        </dd>
                    </div>
                    <div className="AdminAlign">

                        <dt>description

                        </dt>

                        <dd>
                            
                            {GalleryCategoryObject.length > 0 && (<p>{GalleryCategoryObject[0].description}</p>)}

                        </dd>
                    </div>

                    <div className="AdminAlign">

                        <dt>
                            Visibility
                        </dt>

                        <dd>
                            
                            {GalleryCategoryObject.length > 0 && (<p>{GalleryCategoryObject[0].isactive}</p>)}

                        </dd>
                    </div>
                    <div className="AdminAlign">

                        <dt>
                            Priority
                        </dt>

                        <dd>

                            {GalleryCategoryObject.length > 0 && (<p>{GalleryCategoryObject[0].priority}</p>)}

                        </dd>
                    </div>








                    <div className="AdminAlign">

                        <dt>
                            Created Date
                        </dt>

                        <dd>
                            {formattedCDate}

                        </dd>
                    </div>
                    <div className="AdminAlign">

                        <dt>
                            Last Edited
                        </dt>

                        <dd>
                            {formattedEDate}


                        </dd>
                    </div>
                </dl>

                <Link to={ROUTES.gallerycategory.name}>Go Back</Link>
                {/* <div className='text-primary d-inline' onClick={()=>navigate(`/contactDetail/${GalleryCategoryObject.id}`)}>Detail</div> */}

            </div>
        </div>
    )
}

export default GalleryCategoryDetail;