import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GalleryList = () => {
    const [listOfGallery, setListOfGallery] = useState([]);
    // const [editValues, setEditValues] = useState(null);
    const [currentId, setCurrentId] = useState("");
    const notifyDelete = () => toast("Item Deleted Successfully!", {
        hideProgressBar: false,
        pauseOnHover: false,
        hideProgressBar: true,
        style: {
            style: {
                color: '#008000',
            }
        }
    });


    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/GalleryAllGet.php").then((response) => {
            setListOfGallery(response.data);
       
        })

    }, [])

    let navigate = useNavigate();
    //for delete request
    const deleteContact = () => {
        console.log(currentId);
        fetch(`https://bntserver.bntfoundation.com/GalleryDelete.php?id=${currentId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
          
        }).then(() => {
            // listOfContacts(response.data);
            setListOfGallery(listOfGallery.filter(function (item) {
                return item.id !== currentId;
            }))
        }).then(() => {
            hideDeleteModal();
            notifyDelete();
        })

    }

    // const handleEditClick = (value) => {
    //     setEditValues(value);
    // };

    const showDeleteModal = (GalleryId) => {
        document.getElementById("deleteModal").style.display = "block";

        setCurrentId(GalleryId);

    }
    const hideDeleteModal = () => {

        document.getElementById("deleteModal").style.display = "none";

    }

    return (
        <div className='w-100 trs-100'>
            <div className="modal" id='deleteModal'>
                <div className="modal-dialog shadow-box" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ">Confirm Delete</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideDeleteModal}>
                                <span aria-hidden="true"></span>
                            </button>
                        </div>
                        <div className="modal-body text-dark">
                            <p>Are you sure you want to delete?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger mx-3" onClick={deleteContact}>Yes</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={hideDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />

            <h2>Gallery List</h2>
            <p>
                <Link title="Home Page" to={ROUTES.addgallery.name}>Add Gallery Image</Link>
            </p>
            <div class="row">

                {listOfGallery.map((value, key) => {
                    const createdAtDate = new Date(value.createdAt);
                    const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
                    return (
                        <div class="col-6 col-md-4 col-lg-3 mb-3" key={key}>
                            <div class="AdminGal">
                                <a href={`https://bntserver.bntfoundation.com/${value.image}`} data-lightbox="image" data-title={value.title}>
                                    <div class="imageCover " style={{
                                         backgroundImage: `url(https://bntserver.bntfoundation.com/${value.image.replace(/\\/g, '/')})`
                                    }}>
                                    </div>
                                </a>

                                <div class="textCase">
                                    <p class="text-success">
                                        {formattedDate}
                                    </p>
                                    <h6 class="text-black">
                                        {value.category}
                                    </h6>
                                    <p>
                                        {value.title}
                                    </p>
                                    <p className={value.isactive==1 ?'text-success':'text-danger'}>

                                        {value.isactive==1 ? "Visible"
                                        : "Not Visible"}

                            
                                    </p>
                                    <p>
                                        <div className='text-primary d-inline onHover mx-2' onClick={() => navigate(`/editgallery/${value.id}`)}>Edit</div>
                                        <div className='text-danger d-inline onHover mx-2' onClick={() => showDeleteModal(value.id)}>Delete</div>
                                    </p>
                                </div>

                            </div>
                        </div>

                    );
                })}

            </div>
        </div >

    )
}

export default GalleryList;