import { React, useEffect, useState, useRef } from 'react';
import { Link, Outlet, Route, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import axios from "axios";
import Slider from "react-slick";


function Gallery() {
    const [listOfGallery, setListOfGallery] = useState([]);

   


    let navigate = useNavigate();
    useEffect(() => {
        fetchGalleryData();
    }, []);

    const fetchGalleryData = async () => {
        try {
            // const response = await fetch('http://csm.3scoders.in/get.php');
            const response = await fetch('https://bntserver.bntfoundation.com/GalleryCategoriesActiveGet.php');
            const data = await response.json();
            console.log(data);
            setListOfGallery(data);
        } catch (error) {
            console.error('Error fetching gallery data:', error);
        }
    };

    const handleClickTop = () => {
        window.scrollTo(0, 330);
    };
    const readMore = (id) => {
        handleClickTop();
        navigate(`/GalleryDetail/${id}`);
    }
    return (
        <div>

            <div class="hero page-inner overlay"
                style={{ backgroundImage: `url('../../Content/img/slide/BNT5.jpg')` }}>
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-9 text-center mt-5">
                            <h1 class="heading" data-aos="fade-up">Gallery</h1>

                            <nav aria-label="breadcrumb"
                                data-aos="fade-up"
                                data-aos-delay="200">
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="subgray-back">
                <div className="container pt-5">
                    <div className="row py-5">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-lg-8">
                                <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                    Explore Our Gallery
                                </h2>
                                <h5 className="text-secondary" data-aos="fade-up" data-aos-delay="50">
                                    Baba Nanak Trust Foundation, Non-Profit Organization
                                </h5>
                            </div>
                        </div>


                        {listOfGallery.map((value, key) => {
                            // const createdAtDate = new Date(value.createdAt);
                            // const options = { day: 'numeric', month: 'long', year: 'numeric' };
                            // const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
                            return (
                                <div className="col-12 col-md-6 col-lg-4 mb-5" data-aos="fade-up" data-aos-delay="200" key={key}>
                                    <div className="AdminGal1">

                                        <div onClick={() => readMore(value.id)} className="link-acent">
                                            {/* <div class="imageCover2 " style={{
                                                backgroundImage: `url(./${encodeURIComponent(`https://bntserver.bntfoundation.com/${value.image}`.replace(/\\/g, '/'))})`
                                            }}></div> */}


                                          
                                            <div className="imageCover2" style={{
                                                backgroundImage: `url(https://bntserver.bntfoundation.com/${value.image.replace(/\\/g, '/')})`
                                            }}></div>

                                        </div>

                                        <div className="textCase1">

                                            <h4 className="text-black my-3">

                                                <div onClick={() => readMore(value.id)} className="link-acent">
                                                    {/* @Html.DisplayFor(modelItem => item.Category) */}
                                                    {value.category}
                                                </div>

                                            </h4>
                                            {/* @{
                                        var date = Convert.ToDateTime(item.Date);
                                    } */}
                                            <p className="text-success">
                                                {/* @date.ToString("dd MMM yyy") */}
                                               

                                            </p>
                                            {/* @{
                                        var text = "";
                                        if (item.Description == null)
                                        {
                                            text = "";
                                        }
                                        else if (item.Description.Length >= 200)
                                        {
                                            text = item.Description.Substring(0, 200) + "...";
                                        }
                                        else
                                        {
                                            text = item.Description;
                                        }
                                    } */}
                                            <p>
                                                {value.description}
                                            </p>
                                            <p className="text-primary">
                                                <div onClick={() => readMore(value.id)} className="link-acent">
                                                    View Gallery
                                                </div>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}




                    </div>
                </div>
            </div>



        </div>
    )
}

export default Gallery;