import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { redirect, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
function Donation() {
  const [listOfItems, setListofItems] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  const initialValues = {
    name: '',
    email: '',
    address: '',
    paymentmethod: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required!'),
    email: Yup.string().required('This field is required!'),

    paymentmethod: Yup.string().required('This field is required!'),
  });
  const onSubmit = async (data, { resetForm, setSubmitting }) => {
    try {
      await axios
        .post('https://bntserver.bntfoundation.com/PaymentPost.php', data, {})
        .then((res) => {
          console.log('it worked', res);
          if (data.paymentmethod === 'debitcardorcreditcard') {
            window.location.href = 'https://buy.stripe.com/fZe16bcNK5md4IoaEE'; // Redirect to another website
          } else {
            setIsQRModalOpen(true); // Open the QR modal
          }
          resetForm();
        });
    } catch (error) {
      console.error('Error while submitting', error);
    }
  };
  return (
    <div>
      <div className="gray-back">
        <div
          className="hero page-inner overlay"
          style={{ backgroundImage: `url('../../Content/img/slide/BNT5.jpg')` }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-9 text-center mt-5">
                <h1 className="heading" data-aos="fade-up">
                  Donation
                </h1>

                <nav
                  aria-label="breadcrumb"
                  data-aos="fade-up"
                  data-aos-delay="200"
                ></nav>
              </div>
            </div>
          </div>
        </div>
        <section className="donate-section">
          <div className="container">
            <div className="row py-5">
              <div className="donateDiv col-lg-6 col-12 ">
                <div className="w-100 px-4">
                  {initialValues && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting, values, setFieldValue }) => (
                        <Form
                          className="custom-form donate-form"
                          action="#"
                          method="get"
                          role="form"
                        >
                          <h3 className="mb-4">Make a donation</h3>

                          <div className="row">
                            {/* <div className="col-lg-12 col-12">
                        <h5 className="mb-3">Donation Frequency</h5>
                      </div>

                      <div className="col-lg-6 col-6 form-check-group form-check-group-donation-frequency">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="DonationFrequency"
                            id="DonationFrequencyOne"
                            checked
                          />

                          <label
                            className="form-check-label"
                            for="DonationFrequencyOne"
                          >
                            One Time
                          </label>
                        </div>
                      </div> */}

                            {/* <div className="col-lg-6 col-6 form-check-group form-check-group-donation-frequency">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="DonationFrequency"
                            id="DonationFrequencyMonthly"
                          />

                          <label
                            className="form-check-label"
                            for="DonationFrequencyMonthly"
                          >
                            Monthly
                          </label>
                        </div>
                      </div> */}

                            {/* <div className="col-lg-12 col-12">
                        <h5 className="mt-2 mb-3">Select an amount</h5>
                      </div>

                      <div className="col-lg-3 col-md-6 col-6 form-check-group">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            $10
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-6 form-check-group">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            $15
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-6 form-check-group">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault3"
                          >
                            $20
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-6 form-check-group">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault4"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault4"
                          >
                            $30
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-6 form-check-group">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault5"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault5"
                          >
                            $45
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-6 form-check-group">
                        <div className="form-check form-check-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault6"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault6"
                          >
                            $50
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6 col-12 form-check-group">
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon1">
                            $
                          </span>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Custom amount"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div> */}

                            <div className="col-lg-12 col-12">
                              <h5 className="mt-1">Personal Info</h5>
                            </div>

                            <div className="col-lg-6 col-12 mt-2">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                style={{ color: 'red' }}
                              />
                            </div>

                            <div className="col-lg-6 col-12 mt-2">
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Email@gmail.com"
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                style={{ color: 'red' }}
                              />
                            </div>
                            <div className="col-lg-6 col-12 mt-2">
                              <Field
                                type="address"
                                name="address"
                                id="address"
                                className="form-control"
                                placeholder="Enter your Address"
                              />
                              <ErrorMessage
                                name="address"
                                component="span"
                                style={{ color: 'red' }}
                              />
                            </div>

                            <div className="col-lg-12 col-12">
                              <h5 className="mt-4 pt-1">Choose Payment</h5>
                            </div>

                            <div className="col-lg-12 col-12 mt-2">
                              <div className="radio-item">
                                <input
                                  type="radio"
                                  id="debitcardorcreditcard"
                                  value="debitcardorcreditcard"
                                  checked={
                                    values.paymentmethod ===
                                    'debitcardorcreditcard'
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      'paymentmethod',
                                      'debitcardorcreditcard'
                                    )
                                  }
                                />
                                <label htmlFor="debitcardorcreditcard">
                                  Debit Card or Credit Card
                                </label>
                              </div>
                              <div className="radio-item">
                                <input
                                  type="radio"
                                  id="byQr"
                                  value="byQr"
                                  checked={values.paymentmethod === 'byQr'}
                                  onChange={() =>
                                    setFieldValue('paymentmethod', 'byQr')
                                  }
                                />
                                <label htmlFor="byQr">By QR</label>
                              </div>

                              <ErrorMessage
                                name="paymentmethod"
                                component="span"
                                style={{ color: 'red' }}
                              />
                              <button
                                type="submit"
                                className="btn-secondary btn mt-4"
                              >
                                Submit Donation
                              </button>
                              <Modal
                                isOpen={isQRModalOpen}
                                onRequestClose={() => setIsQRModalOpen(false)}
                                className="modal-content qrModal"
                                id="qrModal"
                                tabIndex="-1"
                                aria-labelledby="qrModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">QR Code</h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setIsQRModalOpen(false)}
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div style={{ textAlign: 'center' }}>
                                    <img
                                      src="Content/img/qr.png"
                                      alt="QR Code"
                                      width={300}
                                      height={300}
                                    />
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Donation;
