import { React, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

function AddGallery() {
    const [galleryCategoryList, setGalleryCategoryList] = useState([]);
    let navigate = useNavigate();

    const initValues = {
        catid: "",
        image: "",
        title: "",
        isactive: "",
        priority: "",
    }


    useEffect(() => {
        axios.get("https://bntserver.bntfoundation.com/GalleryCategoriesAllGet.php").then((response) => {
            setGalleryCategoryList(response.data);
            // console.log(response.data);
        })
    }, [])
    const submit = async (data, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("catid", data.catid);
            formData.append("title", data.title);
            formData.append("priority", data.priority);
            formData.append("image", data.image);
            formData.append("isactive", data.isactive);
            await axios.post("https://bntserver.bntfoundation.com/GalleryPost.php", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            window.location.href = '/gallerylist';
        }
        catch (error) {
            console.error(error);

        }
        finally {
            setSubmitting(false);
        }

    }
    const validation1 = Yup.object().shape({
        catid: Yup.string().required(),
        image: Yup.string().required("Image is must for Gallery"),
        title: Yup.string().max(250).required(),
        priority: Yup.number().min(0).max(50).required(),
    })
    return (
        <div>
            <h2>Add Gallery</h2>


            <Formik initialValues={initValues} onSubmit={submit} validationSchema={validation1}>
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">


                            <div className="form-group">

                                <lable className="control-label col-md-2">Category</lable>
                                <div className="col-md-10">

                                    <ErrorMessage name="catid" component="span" className='text-danger' />
                                    {/* <Field name="catid" className="form-control" placeholder="Category" /> */}
                                    <Field as="select" name="catid" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        {galleryCategoryList.map((value, key) => {
                                            return (
                                                <option value={value.id}>{value.category}</option>
                                            );
                                        })}
                                    </Field>
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Title</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="title" component="span" className='text-danger' />
                                    <Field name="title" className="form-control" placeholder="Title" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="Priority" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Image</lable>

                                <div className="col-md-10">
                                    <div className='my-1'><ErrorMessage name="image" component="span" className='text-danger' /></div>
                                    <input type="file" name="image" onChange={(event) => {
                                        setFieldValue("image", event.target.files[0]);
                                    }} />
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>



                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="isactive" component="span" className='text-danger' />
                                    <Field as="select" name="isactive" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="1" label="true"></option>
                                        <option value="0" label="false"></option>
                                    </Field>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                                                type="submit"

                                                className="btn btn-primary"
                                            >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


            <div>

            </div>
        </div>
    )
}

export default AddGallery;