import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';



const TeamDetail = () => {

  let { id } = useParams();
  const [TestimonialObject, setTestimonialObject] = useState({});
  const [formattedCDate, setFormattedCDate] = useState("");
  const [formattedEDate, setFormattedEDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://bntserver.bntfoundation.com/TeamGetById.php?id=${id}`);
        const data = await response.json();

        setTestimonialObject(data);

        const createdAtDate1 = new Date(TestimonialObject.createdAt);
        const options1 = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);
        setFormattedCDate(formattedDate1);

        const createdAtDate2 = new Date(TestimonialObject.updatedAt);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate2 = createdAtDate2.toLocaleDateString('en-CA', options);
        setFormattedEDate(formattedDate2);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();


    // axios.get(`https://bntserver.bntfoundation.com/TestimonialGetById.php?id=${id}`).then((response) => {
    //     setTestimonialObject(response.data);
    // }).then(()=>{
    //   //Date Change
    //   const createdAtDate1 = new Date(TestimonialObject.createdAt);
    //   const options1 = { day: 'numeric', month: 'long', year: 'numeric' };
    //   const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);
    //   setFormattedCDate(formattedDate1);

    //   const createdAtDate2 = new Date(TestimonialObject.updatedAt);
    //   const options = { day: 'numeric', month: 'long', year: 'numeric' };
    //   const formattedDate2 = createdAtDate2.toLocaleDateString('en-CA', options);
    //   setFormattedEDate(formattedDate2);
    // })

  }, [formattedCDate, id])


  //   let navigate = useNavigate();

  return (
    <div>
      <h2>Testimonial Detail</h2>
      <div>
        <dl className="dl-horizontal">
          <div className="AdminAlign">

            <dt>
              Image
            </dt>

            <dd>
             
              {TestimonialObject.length > 0 && (<img width={'150px'} src={`https://bntserver.bntfoundation.com/${TestimonialObject[0].image}`}/>)}
              

            </dd>
          </div>
          <div className="AdminAlign">
            <dt>
              Name
            </dt>

            <dd>
              {TestimonialObject.length > 0 && (<p>{TestimonialObject[0].name}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>Email

            </dt>

            <dd>

              {TestimonialObject.length > 0 && (<p>{TestimonialObject[0].email}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>
              Subject
            </dt>

            <dd>

              {TestimonialObject.length > 0 && (<p>{TestimonialObject[0].profession}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>
              Massage
            </dt>

            <dd>

              {TestimonialObject.length > 0 && (<p>{TestimonialObject[0].bio}</p>)}

            </dd>
          </div>

        
          <div className="AdminAlign">

            <dt>
              Visibility
            </dt>

            <dd>
              
              {TestimonialObject.length > 0 && (<p>{TestimonialObject[0].isactive}</p>)}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>
              Priority
            </dt>

            <dd>
              
              {TestimonialObject.length > 0 && (<p>{TestimonialObject[0].priority}</p>)}

            </dd>
          </div>

          <div className="AdminAlign">

            <dt>
              Created Date
            </dt>

            <dd>
              {formattedCDate}

            </dd>
          </div>
          <div className="AdminAlign">

            <dt>
              Last Edited
            </dt>

            <dd>
              {formattedEDate}


            </dd>
          </div>
        </dl>

        <Link to={ROUTES.testimoniallist.name}>Go Back</Link>
        {/* <div className='text-primary d-inline' onClick={()=>navigate(`/contactDetail/${ContactObject.id}`)}>Detail</div> */}

      </div>
    </div>
  )
}

export default TeamDetail;