import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

function AddGalleryCategory() {

    let navigate = useNavigate();
    const initValues = {
        category: "",
        description: "",
        priority: "",
        image: "",
        isactive: "",

    }

    const Redirec = () => {
        navigate('/GalleryCategory');
        window.location.href = '/GalleryCategory';
    }

    const submit = async (data, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("category", data.category);
            formData.append("description", data.description);
            formData.append("priority", data.priority);
            formData.append("image", data.image); // Assuming data.image is the file object
            formData.append("isactive", data.isactive);
         

            await axios.post("https://bntserver.bntfoundation.com/GalleryCategoriesPost.php", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            Redirec();
            console.log("Data submitted successfully:", data);
            // Redirect logic can be placed here if needed
        } catch (error) {
            console.error("Error submitting data:", error);
        } finally {
            setSubmitting(false);
        }
    }
    
    const validation1 = Yup.object().shape({
        category: Yup.string().required(),
        description: Yup.string().required(),
        priority: Yup.string().required(),
        isactive: Yup.string().required(),
    
    })
    return (
        <div>
            <h2>Add Gallery Category</h2>


            <Formik initialValues={initValues} onSubmit={submit} validationSchema={validation1}>
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">


                            <div className="form-group">

                                <lable className="control-label col-md-2">Category</lable>
                                <div className="col-md-10">
                                    {/* @Html.EditorFor(model => model.Name, new { htmlAttributes = new { @class = "form-control" } }) */}

                                    {/* @Html.ValidationMessageFor(model => model.Name, "", new { @class = "text-danger" }) */}
                                    <ErrorMessage name="category" component="span" className='text-danger' />
                                    <Field name="category" className="form-control" placeholder="Category" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Description</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="description" component="span" className='text-danger' />
                                    <Field name="description" className="form-control" placeholder="Description" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="Priority" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Image</lable>

                                <div className="col-md-10">
                                    <input type="file" name="image" onChange={(event) => {
                                        setFieldValue("image", event.target.files[0]);
                                    }} />
                                    {/* <ErrorMessage name="image" component="span" className='text-danger' /> */}
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>

                            

                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="isactive" component="span" className='text-danger' />
                                    <Field as="select" name="isactive" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="1" label="true"></option>
                                        <option value="0" label="false"></option>
                                    </Field>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                                                type="submit"

                                                className="btn btn-primary"
                                            >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


            <div>

            </div>
        </div>
    )
}

export default AddGalleryCategory;