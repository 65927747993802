import React from 'react'
import axios from "axios";


import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';


function EditGalleryCategory() {

    let { id } = useParams();
    const [GalleryCategoryObject, setGalleryCategoryObject] = useState({});

    useEffect(() => {
        axios.get(`http://localhost:3001/gallerycategory/byId/${id}`).then((response) => {
            setGalleryCategoryObject(response.data);
        })
    }, [id])

   
const onUpdate = async (data) => {
    try {
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("category", data.category);
        formData.append("description", data.description);
        formData.append("priority", data.priority);
        formData.append("isactive", data.isactive);
    
        // Append the image file if it exists
        if (data.image instanceof File) {
            formData.append("image", data.image);
        }

        const response = await axios.put("https://bntserver.bntfoundation.com/GalleryCategoriesPut.php", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                // Add any additional headers if required
            },
        });

        console.log("Data submitted successfully:", response.data);
    } catch (error) {
        console.error("Error updating establishment:", error);
    }
};
    


    return (
        <div>
            <h2>Edit Gallery Category</h2>



            <Formik initialValues={GalleryCategoryObject} onSubmit={onUpdate}  enableReinitialize={true}>
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">


                            <div className="form-group">

                                <lable className="control-label col-md-2">Category</lable>
                                <div className="col-md-10">
                                    {/* @Html.EditorFor(model => model.Name, new { htmlAttributes = new { @class = "form-control" } }) */}

                                    {/* @Html.ValidationMessageFor(model => model.Name, "", new { @class = "text-danger" }) */}
                                    <ErrorMessage name="category" component="span" className='text-danger' />
                                    <Field name="category" className="form-control" placeholder="Category" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Description</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="description" component="span" className='text-danger' />
                                    <Field name="description" className="form-control" placeholder="Description" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="Priority" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Image</lable>

                                <div className="col-md-10">
                                    <input type="file" name="image" onChange={(event) => {
                                        setFieldValue("image", event.target.files[0]);
                                    }} />
                                    {/* <ErrorMessage name="image" component="span" className='text-danger' /> */}
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>



                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="isactive" component="span" className='text-danger' />
                                    <Field as="select" name="isactive" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="true" label="true"></option>
                                        <option value="false" label="false"></option>
                                    </Field>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                        type="submit"

                        className="btn btn-primary"
                    >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


            <div>

            </div>
        </div>
    )
}

export default EditGalleryCategory;