import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";



function EditGallery() {

    let { id } = useParams();
    const [GalleryObject, setGalleryObject] = useState({});

    useEffect(() => {
        axios.get(`http://localhost:3001/Gallery/byId/${id}`).then((response) => {
            setGalleryObject(response.data);
        })
    }, [id])

    const onUpdate = async (data) => {
        try {
            const formData = new FormData();
            formData.append("id", data.id);
            formData.append("catid", data.catid);
            formData.append("title", data.title);
            formData.append("priority", data.priority);
            formData.append("image", data.image);
            formData.append("isactive", data.isactive);


            await axios.put("http://localhost:3001/Gallery", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            window.location.href = '/gallerylist';

        } catch (error) {
            console.error("Error updating establishment:", error);
        }
    };
    const validation1 = Yup.object().shape({
        catid: Yup.string().required(),
        image: Yup.string().required("Image is must for Gallery"),
        title: Yup.string().max(250).required(),
        priority: Yup.number().min(0).max(50).required(),
    })


    return (
        <div>
            <h2>Edit Gallery</h2>



            <Formik initialValues={GalleryObject} onSubmit={onUpdate} enableReinitialize={true} validationSchema={validation1}>
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">


                            <div className="form-group">

                                <lable className="control-label col-md-2">Category</lable>
                                <div className="col-md-10">

                                    <ErrorMessage name="catid" component="span" className='text-danger' />
                                    <Field name="catid" className="form-control" placeholder="Category" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Title</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="title" component="span" className='text-danger' />
                                    <Field name="title" className="form-control" placeholder="Title" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="Priority" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Image</lable>

                                <div className="col-md-10">
                                    <div className='my-1'><ErrorMessage name="image" component="span" className='text-danger' /></div>
                                    <input type="file" name="image" onChange={(event) => {
                                        setFieldValue("image", event.target.files[0]);
                                    }} />
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>



                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="isactive" component="span" className='text-danger' />
                                    <Field as="select" name="isactive" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="true" label="true"></option>
                                        <option value="false" label="false"></option>
                                    </Field>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                        type="submit"

                        className="btn btn-primary"
                    >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default EditGallery;