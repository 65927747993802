// rfce
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

function AddTeam() {

    let navigate = useNavigate();
    const initValues = {
        name: "",
        email: "",
        profession: "",
        bio: "",
        image: "",
        
        visibility: "",
        priority: "",
    }

    const Redirec = () => {
        navigate('/teamlist');
        window.location.href = '/teamlist';
    }

    const submit = async (data, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("profession", data.profession);
            formData.append("bio", data.bio);
            formData.append("image", data.image);
            formData.append("isactive", data.isactive);
            formData.append("priority", data.priority);

            await axios.post("https://bntserver.bntfoundation.com/TeamPost.php", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            setTimeout(() => {
                Redirec();
            }, 200);



        }
        catch (error) {
            console.error(error);

        }
        finally {
            setSubmitting(false);
        }

    }
    const validation1 = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().required(),
        profession: Yup.string().required(),
        bio: Yup.string().required(),
        priority: Yup.string().min(1).max(20).required("Set Priority from 1 to 20"),

    })
    return (
        <div>
            <h2>Add Team</h2>


            <Formik initialValues={initValues} onSubmit={submit} validationSchema={validation1}>
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">


                            <div className="form-group">

                                <lable className="control-label col-md-2">Name</lable>
                                <div className="col-md-10">
                                    {/* @Html.EditorFor(model => model.Name, new { htmlAttributes = new { @class = "form-control" } }) */}

                                    {/* @Html.ValidationMessageFor(model => model.Name, "", new { @class = "text-danger" }) */}
                                    <ErrorMessage name="name" component="span" className='text-danger' />
                                    <Field name="name" className="form-control" placeholder="Name" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Email</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="email" component="span" className='text-danger' />
                                    <Field name="email" className="form-control" placeholder="Email" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Profession</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="profession" component="span" className='text-danger' />
                                    <Field name="profession" className="form-control" placeholder="profession" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Bio</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="bio" component="span" className='text-danger' />
                                    <Field name="bio" className="form-control" placeholder="bio" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Image</lable>

                                <div className="col-md-10">
                                    <input type="file" name="image" onChange={(event) => {
                                        setFieldValue("image", event.target.files[0]);
                                    }} />
                                    {/* <ErrorMessage name="image" component="span" className='text-danger' /> */}
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>

                            


                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="isactive" component="span" className='text-danger' />
                                    <Field as="select" name="isactive" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="1" label="true"></option>
                                        <option value="0" label="false"></option>
                                    </Field>
                                </div>
                            </div>
                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="priority" />
                                </div>
                            </div>


                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                                                type="submit"

                                                className="btn btn-primary"
                                            >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


            <div>

            </div>
        </div>
    )
}

export default AddTeam;