// import '../../Client/public/Content/js/owl.carousel.js';

import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import MusicBackground from './MusicBackground';
import { Link, Outlet, Route } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';
import { useState, useEffect } from 'react';

function Layout() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    useEffect(() => {
        // Update the current year when the component mounts
        setCurrentYear(new Date().getFullYear());
    }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

    const handleClickTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <MusicBackground/>
            <Navbar></Navbar>

            <Outlet />

            <div className="site-footer">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="widget">
                                <h3 data-aos="fade-left">Contact</h3>
                                <address data-aos="fade-left" data-aos-delay="100">6807 – 18 Ave SW Edmonton, AB T6X 0C9</address>
                                <ul className="list-unstyled links">
                                    <li data-aos="fade-left" data-aos-delay="200"><a href="tel://780-616-5057">780-616-5057</a></li>

                                    <li data-aos="fade-left" data-aos-delay="300">
                                        <a href="mailto:seva@babananaktrust.ca">seva@babananaktrust.ca</a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="col-lg-4">
                            <div className="widget">
                                <h3 data-aos="fade-left">Sources</h3>
                                <ul className="list-unstyled float-start links">
                                    <li data-aos="fade-left" data-aos-delay="50"><Link to={ROUTES.about.name} onClick={handleClickTop}>About</Link></li>
                                    <li data-aos="fade-left" data-aos-delay="100"><Link to={ROUTES.about.name} onClick={handleClickTop}>Vision</Link></li>
                                    <li data-aos="fade-left" data-aos-delay="200"><Link to={ROUTES.about.name} onClick={handleClickTop}>Mission</Link></li>
                                    <li data-aos="fade-left" data-aos-delay="300"><Link to={ROUTES.team.name} onClick={handleClickTop}>Our Team</Link></li>
                                    <li data-aos="fade-left" data-aos-delay="400"><Link to={ROUTES.logIn.name} onClick={handleClickTop}>Login</Link></li>
                                </ul>
                                <ul className="list-unstyled float-start links">
                                    <li><a href="#" data-aos="fade-left" data-aos-delay="50">Careers</a></li>
                                    <li><Link to={ROUTES.blog.name} data-aos="fade-left" data-aos-delay="100" onClick={handleClickTop}>Blog</Link></li>
                                    <li><Link to={ROUTES.gallery.name} data-aos="fade-left" data-aos-delay="200" onClick={handleClickTop}>Gallery</Link></li>
                                    <li><Link to={ROUTES.contact.name} data-aos="fade-left" data-aos-delay="300" onClick={handleClickTop}>Contact</Link></li>
                                    <li><Link data-aos="fade-left" data-aos-delay="400" to={ROUTES.privacypolicy.name} onClick={handleClickTop}>Privacy Policy</Link></li>
                                    <li><Link data-aos="fade-left" data-aos-delay="400" to={ROUTES.gettheapp.name} onClick={handleClickTop}>Download Saathi App</Link></li>



                                </ul>
                            </div>

                        </div>

                        <div className="col-lg-4">
                            <div className="widget">
                                <h3 data-aos="fade-left">Links</h3>
                                <ul className="list-unstyled links">
                                    <li data-aos="fade-left" data-aos-delay="100"><a href="#">Our Vision</a></li>
                                    <li data-aos="fade-left" data-aos-delay="200"><a href="#">About us</a></li>
                                    <li data-aos="fade-left" data-aos-delay="300"><a href="#">Contact us</a></li>
                                </ul>

                                <ul className="list-unstyled social">
                                    <li data-aos="fade-left" className='mr-2'>
                                        <a href="#"><span className="icon-instagram"></span></a>
                                    </li>
                                    <li data-aos="fade-left" className='mr-2' data-aos-delay="100">
                                        <a href="https://www.youtube.com/@BabaNanakTrustFoundation"><span className="icon-youtube"></span></a>
                                    </li>
                                    <li data-aos="fade-left" className='mr-2' data-aos-delay="200">
                                        <a href="https://www.facebook.com/babananaktrust/" target='_blank'><span className="icon-facebook"></span></a>
                                    </li>



                                </ul>
                            </div>

                        </div>

                    </div>


                    <div className="row mt-5">
                        <div className="col-12 text-center">


                            <p>
                                Copyright &copy; {currentYear}. All Rights Reserved Baba Nanak Trust Foundation. Designed & Developed by
                                <a className='ml-1' href="https://whizinfo.com/">Whizinfo Technologies</a>

                            </p>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Layout;