// rfce
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

function AddTestimonial() {

    let navigate = useNavigate();
    const initValues = {
        name: "",
        email: "",
        profession: "",
        message: "",
        image: "",
        rating: "",
        visibility: "",
        priority: "",
    }

    const Redirec = () => {
        navigate('/testimoniallist');
        window.location.href = '/testimoniallist';
    }

    const submit = async (data, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("profession", data.profession);
            formData.append("message", data.message);
            formData.append("image", data.image);
            formData.append("rating", data.rating);
            formData.append("visibility", data.visibility);
            formData.append("priority", data.priority);

            await axios.post("https://bntserver.bntfoundation.com/TestimonialPost.php", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            setTimeout(() => {
                Redirec();
            }, 200);



        }
        catch (error) {
            console.error(error);

        }
        finally {
            setSubmitting(false);
        }

    }
    const validation1 = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().required(),
        profession: Yup.string().required(),
        message: Yup.string().required(),
        rating: Yup.number().min(1).max(5).required("Select 1 to 5 rating"),
        priority: Yup.string().min(1).max(20).required("Set Priority from 1 to 20"),

    })
    return (
        <div>
            <h2>Add Testimonial</h2>


            <Formik initialValues={initValues} onSubmit={submit} validationSchema={validation1}>
                {({ values, setFieldValue, isSubmitting }) => (


                    <Form>
                        <div className="form-horizontal">


                            <div className="form-group">

                                <lable className="control-label col-md-2">Name</lable>
                                <div className="col-md-10">
                                    {/* @Html.EditorFor(model => model.Name, new { htmlAttributes = new { @class = "form-control" } }) */}

                                    {/* @Html.ValidationMessageFor(model => model.Name, "", new { @class = "text-danger" }) */}
                                    <ErrorMessage name="name" component="span" className='text-danger' />
                                    <Field name="name" className="form-control" placeholder="Name" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Email</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="email" component="span" className='text-danger' />
                                    <Field name="email" className="form-control" placeholder="Email" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Profession</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="profession" component="span" className='text-danger' />
                                    <Field name="profession" className="form-control" placeholder="profession" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Message</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="message" component="span" className='text-danger' />
                                    <Field name="message" className="form-control" placeholder="message" />
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2">Image</lable>

                                <div className="col-md-10">
                                    <input type="file" name="image" onChange={(event) => {
                                        setFieldValue("image", event.target.files[0]);
                                    }} />
                                    {/* <ErrorMessage name="image" component="span" className='text-danger' /> */}
                                    {/* <Field name="image" className="form-control" placeholder="image" /> */}
                                </div>
                            </div>

                            <div className="form-group">
                                <lable className="control-label col-md-2 mb-4">Rating</lable>
                                <div><ErrorMessage name="rating" component="span" className='text-danger' /></div>
                                <div className="">
                                    <lable className=" col-md-2" htmlFor="1"><Field type='radio' name="rating" className="mx-1" value="1" />1 Star</lable>
                                    <lable className=" col-md-2" htmlFor="2"><Field type='radio' name="rating" className="mx-1" value="2" />2 Star</lable>
                                    <lable className=" col-md-2" htmlFor="3"><Field type='radio' name="rating" className="mx-1" value="3" />3 Star</lable>
                                    <lable className=" col-md-2" htmlFor="4"><Field type='radio' name="rating" className="mx-1" value="4" />4 Star</lable>
                                    <lable className=" col-md-2" htmlFor="5"><Field type='radio' name="rating" className="mx-1" value="5" />5 Star</lable>
                                </div>
                            </div>


                            <div className="form-group">
                                <lable className="control-label col-md-2">visible</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="visibility" component="span" className='text-danger' />
                                    <Field as="select" name="visibility" className="form-control">
                                        <option disabled selected label="Select a visibility"></option>
                                        <option value="1" label="true"></option>
                                        <option value="0" label="false"></option>
                                    </Field>
                                </div>
                            </div>
                            <div className="form-group">
                                <lable className="control-label col-md-2">Priority</lable>

                                <div className="col-md-10">
                                    <ErrorMessage name="priority" component="span" className='text-danger' />
                                    <Field name="priority" className="form-control" placeholder="priority" />
                                </div>
                            </div>


                            <div className="form-group">
                                <div className="col-md-offset-2 col-md-10">
                                    <input type="submit" disabled={isSubmitting} value="submit" className="btn btn-secondary" />
                                    {/* <button
                                                type="submit"

                                                className="btn btn-primary"
                                            >Submit</button> */}

                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>


            <div>

            </div>
        </div>
    )
}

export default AddTestimonial;