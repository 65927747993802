import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';

function ContactConfirmation() {
    let navigate = useNavigate();
    const handleClickTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <div className="hero page-inner overlay"
                style={{ backgroundImage: `url('../../Content/img/slide/BNT1.jpg')` }}>

            </div>
            <div className='confirmContainer'>
                <div className='confirmBox'>
                    <img src="Content/img/succes.png" />
                    <h1 className='text-success'>Thank You!</h1>
                    <h6 className='text-success'>for getting in touch!</h6>
                    <p className='text-center'>We appreciate you contacting Baba Nanak Trust Foundation. We will get back in touch with you soon! Have a great day!</p>
                    <Link className="btn btn-success text-white py-3 px-4" to={ROUTES.index.name} onClick={handleClickTop}>Done</Link>
                </div>
            </div>
        </div>
    )
}

export default ContactConfirmation;