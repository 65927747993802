import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';

function BlogDetail() {
    let { id } = useParams();
    const [BlogObject, setBlogObject] = useState({});
    const [formattedCDate, setFormattedCDate] = useState("");




    //   const BlogObject =
    // {
    //             id: 1,
    //             name: 'BNT 1',
    //             priority: 1,
    //             isactive: true,
    //             writername: 'Baba Nanak Trust Foundation',
    //             writerpic: 'dbImages\\1706077160770-BreakingSAD.jpg',
    //             writerdesc: 'You can add writer description here',
    //             createdAt: '2024-01-09T10:41:18.000Z',
    //             updatedAt: '2024-01-24T06:19:20.000Z',
    //             blogtitle: 'A few weeks we learnt that a mother and her young daughter were fatally attacked outside a southeast Edmonton school.',
    //             blogdesc: 'A few weeks we learnt that a mother and her young daughter were fatally attacked outside a southeast Edmonton school. The loss of life at any time is difficult enough but the senseless attack on unsuspecting people, especially outside a school, is raising serious worries about safety in our neighbourhoods.',
    //             blogtitle2: 'Topic II',
    //             blogdesc2: 'On Monday, City of Edmonton’s Community After Incident Response (CAIR) team, in partnership with the Edmonton Police Service and a number of community organizations, held an information session at the Southwood Community League. The focus was on helping families and the community cope with trauma arising from the tragic event. BNT was once again invited to attend and provide mental health resources. A number of volunteers were present, along with our affiliated volunteers representing Breaking The Silence (BTS). A big thank you to all the volunteers 🙏.',
    //             blogtitle3: 'You can add third Topic here',
    //             blogdesc3: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
    //             blogpic: 'dbImages\\1706077160782-NewBNT14.jpg',
    //             blogurllink: '',
    //             sidedesc: 'You can add side description here',
    //             sidedesctitle: 'Side header',
    //             writerdesc: 'You can add writer description here'
    //         }



    useEffect(() => {
        //     axios.get(`http://localhost:3001/Blogs/byId/${id}`).then((response) => {
        //         setBlogObject(response.data);
        //     }).then(() => {
        //         //Date Change
        //         const createdAtDate1 = new Date(BlogObject.createdAt);
        //         const options1 = { day: 'numeric', month: 'long', year: 'numeric' };
        //         const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);
        //         setFormattedCDate(formattedDate1);
        //     })
        const fetchData = async () => {
            try {
                const response = await fetch(`https://bntserver.bntfoundation.com/BlogGetById.php?id=${id}`);
                const data = await response.json();

                setBlogObject(data);

                const createdAtDate1 = new Date(BlogObject[0].createdAt);
                const options1 = { day: 'numeric', month: 'long', year: 'numeric' };
                const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);
                setFormattedCDate(formattedDate1);



            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [formattedCDate, id])


    let navigate = useNavigate();

    return (
        <div>

            <div className="hero page-inner overlay"
                style={{ backgroundImage: `url('../../Content/img/slide/BNT5.jpg')` }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9 text-center mt-5">
                            <h1 className="heading" data-aos="fade-up">Blog</h1>

                            <nav aria-label="breadcrumb"
                                data-aos="fade-up"
                                data-aos-delay="200">
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div id="blog" className="site-section my-5">
                <div className="container py-5">

                    <div className="row">

                        <div className="col-md-8 px-md-4">

                            <p className="mb-2">{BlogObject.length > 0 && (<img src={`https://bntserver.bntfoundation.com/${BlogObject[0].blogpic}`} alt="" className="img-fluid" />)}</p>


                            <h3 className="mb-3 mt-4 mb-4 font-weight-normal">
                                {BlogObject.length > 0 && (<p>{BlogObject[0].blogtitle}</p>)}
                            </h3>
                            <p className="text-justify text-gray-dark">

                                {BlogObject.length > 0 && (<p>{BlogObject[0].blogdesc}</p>)}

                            </p>
                            <h5 className="mb-3 mt-5 font-weight-normal">
                                {BlogObject.length > 0 && (<p>{BlogObject[0].blogtitle2}</p>)}
                            </h5>
                            <p className="text-justify  text-gray-dark">
                                {BlogObject.length > 0 && (<p>{BlogObject[0].blogdesc2}</p>)}

                            </p>
                            <h5 className="mb-3 mt-5 font-weight-normal">
                                {BlogObject.length > 0 && (<p>{BlogObject[0].blogtitle3}</p>)}

                            </h5>
                            <p className="text-justify  text-gray-dark">
                                {BlogObject.length > 0 && (<p>{BlogObject[0].blogdesc3}</p>)}</p>
                            <h3 className="py-3">
                                {BlogObject.length > 0 && (<p>{BlogObject[0].writername}</p>)}
                            </h3>






                        </div>
                        <div className="col-md-4 px-md-4 sidebar">

                            <div className="sidebar-box">



                                {BlogObject.length > 0 && (<img src={`https://bntserver.bntfoundation.com/${BlogObject[0].writerpic}`} alt="" className="img-fluid mt-0 mb-4 rounded" />)}
                                <h2 className="font-weight-normal">

                                    {BlogObject.length > 0 && (<p>{BlogObject[0].writername}</p>)}
                                </h2>

                               
                                    {BlogObject.length > 0 && (<p className="text-gray-dark text-justify">{BlogObject[0].writerdesc}</p>)}
                                





                                <h5 className="text-success mt-3 font-weight-lighter">Published on {formattedCDate}</h5>

                                <h3 className="mt-4">Check Other Links</h3>

                                <h6 className="mb-3">
                                {BlogObject.length > 0 && (<a className="text-def" target="_blank" href={BlogObject[0].blogurllink}>Click here</a>)}</h6>



                                {BlogObject.length > 0 && (<h5 className="mb-2 mt-4 font-weight-normal">{BlogObject[0].sidedesctitle}
                                

                                </h5>)}
                                {BlogObject.length > 0 && (<p className="text-gray-dark mt-3 text-justify">{BlogObject[0].sidedesc}</p>)}

                            </div>

                        </div>

                    </div>


                </div>
            </div>

        </div>
    )
}

export default BlogDetail;