import React, { useEffect, useRef, useState } from 'react';

const MusicBackground = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false); // Tracks if the music is playing
    const [hasUserScrolled, setHasUserScrolled] = useState(false); // Tracks if user has scrolled

    // Handle the scroll event to trigger audio playback
    const handleScrollInteraction = () => {
        if (!hasUserScrolled) {
            setHasUserScrolled(true); // Mark as scrolled
            const audio = audioRef.current;
            audio.volume = 0.2;
            audio.play().then(() => {
                setIsPlaying(true); // Start playing after scroll interaction
                window.removeEventListener('scroll', handleScrollInteraction); // Remove scroll listener after interaction
            }).catch((error) => {
                console.error("Audio play failed:", error);
            });
        }
    };

    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScrollInteraction);

        return () => {
            // Clean up scroll event listener on unmount
            window.removeEventListener('scroll', handleScrollInteraction);
        };
    }, [hasUserScrolled]);

    // Function to toggle play/pause
    const togglePlayPause = () => {
        const audio = audioRef.current;
        audio.volume = 0.2;
        if (isPlaying) {
            audio.pause();
            setIsPlaying(false);
        } else {
            audio.play().then(() => {
                setIsPlaying(true);
            }).catch((error) => {
                console.error("Audio play failed:", error);
            });
        }
    };

    return (
        <div>
            {/* Audio element */}
            <audio ref={audioRef} src="https://bntfoundation.com/GurbaniAudios/bgMusic.mp3" loop />

            {/* Play/Pause Button */}
            {hasUserScrolled && (
                <button
                    onClick={togglePlayPause}
                    style={{
                        height: 40,
                        width: 40,
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        border: 'none',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 1001
                    }}
                >
                    {isPlaying ? (
                        <i className="fas fa-pause"></i> // Font Awesome Pause Icon
                    ) : (
                        <i className="fas fa-play"></i> // Font Awesome Play Icon
                    )}
                </button>
            )}
        </div>
    );
};

export default MusicBackground;
