import React from 'react';
import axios from "axios";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


function GalleryDetail() {

    let { id } = useParams();
    const [listOfGallery, setListOfGallery] = useState([]);
    const [listOfGalleryC, setListOfGalleryC] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://bntserver.bntfoundation.com/GalleryGet.php?categoryId=${id}`);
                const data = await response.json();

                setListOfGallery(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchCategoryData = async () => {
            try {
                const response = await fetch(`https://bntserver.bntfoundation.com/GalleryCategoryByIdGet.php?id=${id}`);
                const data = await response.json();
                console.log(data);
                setListOfGalleryC(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategoryData();
        fetchData();
    }, [id]);
    // useEffect(() => {
    //     axios.get(`http://localhost:3001/Gallery/byCatId/${id}`).then((response) => {
    //         setListOfGallery(response.data);

    //     })
    // }, [])

    // useEffect(() => {
    //     axios.get(`http://localhost:3001/GalleryCategory/byId/${id}`).then((response) => {
    //         setListOfGalleryC(response.data);
    //     })
    // }, [])


    return (
        <div>

            <div className="gray-back">
                <div className="hero page-inner overlay"
                    style={{ backgroundImage: `url('../../Content/img/slide/BNT5.jpg')` }}>
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-9 text-center mt-5">
                                <h1 className="heading" data-aos="fade-up">Gallery Detail</h1>
                                <nav aria-label="breadcrumb"
                                    data-aos="fade-up"
                                    data-aos-delay="200">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className="row py-5">
                        <div className="row justify-content-center text-center mb-4">
                            <div className="col-lg-10">
                                <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">


                                    {listOfGalleryC.length > 0 && (
                                        <p>{listOfGalleryC[0].category}</p>
                                    )}
                                </h2>
                                <h6 className="" data-aos="fade-up" data-aos-delay="50">
                                    {listOfGalleryC.length > 0 && (
                                        <p>{listOfGalleryC[0].description}</p>
                                    )}

                                </h6>
                            </div>
                        </div>
                        {listOfGallery.map((value, key) => {
                           
                            
                            return (
                                <div className="col-12 col-md-6 col-lg-4 my-4" data-aos="fade-up" key={key}>
                                    <div className="AdminGal1">

                                        <a href={`https://bntserver.bntfoundation.com/${value.image}`} data-lightbox="image">

                                            <div class="imageCover3 " style={{
                                                backgroundImage: `url(https://bntserver.bntfoundation.com/${value.image.replace(/\\/g, '/')})`
                                            }}></div>

                                        </a>
                                        <div className="imageDate">
                                            <p className="text-white m-0">
                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}



                    </div>
                </div>

            </div>

        </div>
    )
}

export default GalleryDetail;