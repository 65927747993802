import React from 'react'
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ROUTES from '../../Nav/Routes';

const BlogDetailA = () => {

    let { id } = useParams();
    const [BlogObject, setBlogObject] = useState({});
    const [formattedCDate, setFormattedCDate] = useState("");
    const [formattedEDate, setFormattedEDate] = useState("");

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`https://bntserver.bntfoundation.com/BlogGetById.php?id=${id}`);
                const data = await response.json();

                setBlogObject(data);
                
                const createdAtDate1 = new Date(BlogObject[0].createdAt);
                const options1 = { day: 'numeric', month: 'long', year: 'numeric' };
                const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);
                setFormattedCDate(formattedDate1);

                const createdAtDate = new Date(BlogObject[0].updatedAt);
                const options = { day: 'numeric', month: 'long', year: 'numeric' };
                const formattedDate = createdAtDate.toLocaleDateString('en-CA', options);
                setFormattedEDate(formattedDate);
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();


        // axios.get(`https://bntserver.bntfoundation.com/BlogGetById.php/${id}`).then((response) => {
        //     setBlogObject(response.data);
        // }).then(() => {
        //     //Date Change
        //     const createdAtDate1 = new Date(BlogObject.createdAt);
        //     const options1 = { day: 'numeric', month: 'long', year: 'numeric' };
        //     const formattedDate1 = createdAtDate1.toLocaleDateString('en-CA', options1);
        //     setFormattedCDate(formattedDate1);
        // })
        // }, [formattedCDate, id])
    }, [formattedCDate, id])


    let navigate = useNavigate();

    return (
        <div>
            <h2 className='mb-2'>Blog Detail</h2>
            <div>
                <dl class="dl-horizontal">
                    <div class="AdminAlign">
                        <dt>
                            Image
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<img src={`https://bntserver.bntfoundation.com/${BlogObject[0].blogpic}`} width={"350px"} />)}
                        </dd>
                    </div>
                    <dd class="h2 my-4">
                        
                        {BlogObject.length > 0 && (<p>{BlogObject[0].name}</p>)}

                    </dd>

                    <div class="AdminAlign lead">
                        <dt>
                            Title -1
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<p>{BlogObject[0].blogtitle}</p>)}
                            {/* {BlogObject.blogtitle} */}


                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Description -1
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<p>{BlogObject[0].blogdesc}</p>)}


                        </dd>
                    </div>
                    <div class="AdminAlign lead">
                        <dt>
                            Title -2
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<p>{BlogObject[0].blogtitle2}</p>)}



                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Description -2

                        </dt>

                        <dd>

                            {BlogObject.length > 0 && (<p>{BlogObject[0].blogdesc2}</p>)}

                        </dd>
                    </div>
                    <div class="AdminAlign lead">
                        <dt>
                            Title -3
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<p>{BlogObject[0].blogtitle3}</p>)}




                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Description -3

                        </dt>

                        <dd>

                            {BlogObject.length > 0 && (<p>{BlogObject[0].blogdesc3}</p>)}


                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            URL
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<a href={`${BlogObject[0].blogurllink}`} target="_blank">{BlogObject[0].blogurllink}</a>)}
                        </dd>
                    </div>

                    <div class="AdminAlign lead">
                        <dt>
                            Writer Name
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<p>{BlogObject[0].writername}</p>)}
                        </dd>
                    </div>
                    <div class="AdminAlign ">
                        <dt>
                            Write Image
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<img src={`https://bntserver.bntfoundation.com/${BlogObject[0].writerpic}`} width={"100px"} />)}
                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Writer Description
                        </dt>

                        <dd>

                            {BlogObject.length > 0 && (<p>{BlogObject[0].writerdesc}</p>)}


                        </dd>
                    </div>
                    <div class="AdminAlign lead">
                        <dt>
                            Side Panel Title
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<p>{BlogObject[0].sidedesctitle}</p>)}

                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Side Panel Description
                        </dt>

                        <dd>
                            {BlogObject.length > 0 && (<p>{BlogObject[0].sidedesc}</p>)}
                        </dd>
                    </div>
                    <div class="AdminAlign ">
                        <dt>
                            Date
                        </dt>

                        <dd class="text-success">
                            {formattedCDate}
                            {BlogObject.length > 0 && (<p>{BlogObject[0].createdAt}</p>)}


                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Modified Date
                        </dt>

                        <dd class="text-info">
                            {formattedEDate}
                            {BlogObject.length > 0 && (<p>{BlogObject[0].updatedAt}</p>)}

                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Visible
                        </dt>

                        <dd>

                            {BlogObject.length > 0 && (<p>{BlogObject[0].isactive}</p>)}

                        </dd>
                    </div>
                    <div class="AdminAlign">
                        <dt>
                            Priority
                        </dt>

                        <dd>

                            {BlogObject.length > 0 && (<p>{BlogObject[0].priority}</p>)}

                        </dd>
                    </div>
                </dl>
            </div>
            <p>
                <Link to={ROUTES.bloglist.name}>Go Back</Link>

            </p>

        </div>

    )
}

export default BlogDetailA;