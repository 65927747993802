import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';

function About() {
    return (
        <div>


            <div className="hero page-inner overlay"
                style={{ backgroundImage: "url('Content/img/slide/BNT5.jpg')" }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9 text-center mt-5">
                            <h1 className="heading" data-aos="fade-up">About</h1>

                            <nav aria-label="breadcrumb"
                                data-aos="fade-up"
                                data-aos-delay="200">
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container mt-5">
                    <div className="row text-left">
                        <div className="row justify-content-center text-center mb-4">
                            <div className="col-lg-6">
                                <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                    About Us
                                </h2>
                                <h5 className="text-secondary" data-aos="fade-up" data-aos-delay="50">
                                    Baba Nanak Trust, A Canadian Charitable Society
                                </h5>
                            </div>
                        </div>

                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <p className="text-gray-dark">
                                Baba Nanak Trust Foundation, BNT, is an Edmonton-based community services society established in 2019 to meet the special needs of socially and psychologically vulnerable South Asians. BNT (pronounced Bay-N-Tee, a humble plea or an emotional prayer) a registered Canadian Charitable Society that is permitted to issue tax-deductible receipts for eligible donations.
                            </p>
                            <p className="text-gray-dark">
                            An extensive engagement with Indo-Canadian community groups and healthcare professionals revealed a growing range of issues, challenges and conflicts developing in both new and settled families in Canada. BNT founders identified an urgent need to raise awareness of and address culturally sensitive mental health related issues to prevent or reduce the escalation of potentially more significant health and social problems in our families.
                            </p>
                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <p className="text-gray-dark">
                                Caring relationships, a place to call home, a supportive community, and work and leisure all contribute to joyful lives. Mental illness is very poorly understood in our community.
                            </p>
                            <p className="text-gray-dark">
                                Each year 1 in 5 experiences a mental health issue.
                            </p>
                            <p className="text-gray-dark">
                                Health professionals say it is a serious disturbance in thoughts, feelings, and perceptions that is severe enough to affect day-to-day functioning. By understanding the causes, we can seek help to find solutions for mental health, social isolation, parenting, and family wellness difficulties.
                            </p>
                            <p className="text-gray-dark">
                            By breaking the silence on the negative stereotyping and stigma of mental health, BNT aims to shift the conversation to mental well-being and mental wealth as a core personal asset.
                            </p>
                            <h5 className="text-secondary mt-4">Our Vision</h5>

                            <p className="text-gray-dark">
                                Selfless service for the socially and psychologically vulnerable individuals in the South Asian Community.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container mt-0">
                    <div className="row text-left mb-3">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-lg-6">
                                <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                    BNT The Beginning
                                </h2>
                                
                            </div>
                        </div>

                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <p className="text-gray-dark">
                            The concept of an organization dedicated to serving the social and wellness needs of the Indo-Canadian community in the greater Edmonton area was originally conceived in 2010 when Punjab United Sports & Heritage Association (PUSHA) was established. However, with passage of time and changing community demographics, it became evident that emerging issues and challenges related to personal, family, and community well-being required a different set of approached and solutions. Quite simply, the world had changed significantly, and new and settled families alike were facing unprecedented levels of stress, anxiety, and depression.
                            </p>
                            <p className="text-gray-dark">
                            Serendipity has played a major role in the assemblage of the founding team. With Kashmir Gill and Iqbal Mahal as co-leads, the journey to find like-minded people began in earnest. Over a period of six months, at coffee shops and gurdwaras, dedicated and committed team members were carefully recruited.
                            </p>
                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <p className="text-gray-dark">
                            While early conversations explored the need for funeral services for South Asians, soon it emerged that mental health was core to the personal well-being of everyone, irrespective of social and financial status. In fact, as a society, we needed to make sure no-one was left behind. The doctrine and teachings of Baba Nanak came to the forefront as they embodied the concept of ‘goodwill to all’ or ‘sarbat da bhalla’. 
                            </p>
                            <p className="text-gray-dark">
                            So, Baba Nanak Trust (BNT) Foundation was born in 2019 with Founder Members: Kashmir Gill, Bhuvinder Gill, Sharan Sandhu, Iqbal Mahal, Nirmal Nijjar, and Manu Bajwa.
                            </p>
                            <p className="text-gray-dark">
                            Baba Nanak Trust Foundation was granted a Canadian Charitable Society registration on June 30, 2022.
                            </p>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="section pt-0">
                <div className="container mt-4">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-lg-5">
                            <h2 className="font-weight-bold heading text-primary " data-aos="fade-up">
                                Our Mission
                            </h2>
                            <h5 className="text-secondary" data-aos="fade-up" data-aos-delay="50">
                                Enhancing lives of individuals who are
                            </h5>
                        </div>
                    </div>
                    <div className="row justify-content-between mb-5">
                        <div className="col-lg-7 mb-5 mb-lg-0 order-lg-2">
                            <div className="img-about dots" data-aos="fade-left" data-aos-delay="200">
                                <img src="Content/img/BreakingSAD.jpg" alt="Image" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex feature-h" data-aos="fade-right">
                                <span className="wrap-icon me-3">
                                    <span className="icon-home2"></span>
                                </span>
                                <div className="feature-text">
                                    <h3 className="heading">Isolated</h3>
                                    <p className="text-gray-dark">
                                        We strive to build a community where no one feels alone. We provide a supportive network to combat isolation and foster meaningful connections
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex feature-h" data-aos="fade-right" data-aos-delay="100">
                                <span className="wrap-icon me-3">
                                    <span className="icon-person"></span>
                                </span>
                                <div className="feature-text">
                                    <h3 className="heading">Abused</h3>
                                    <p className="text-gray-dark">
                                        helping survivors of abuse find their strength, heal, and rebuild their lives with dignity and resilience
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex feature-h" data-aos="fade-right" data-aos-delay="200">
                                <span className="wrap-icon me-3">
                                    <span className="icon-security"></span>
                                </span>
                                <div className="feature-text">
                                    <h3 className="heading">Depressed</h3>
                                    <p className="text-gray-dark">
                                        bring hope and healing to those struggling with depression.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-lg-5" data-aos="fade-up" data-aos-delay="0">
                            <img src="Content/img/pray.jpg" alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-md-4 " data-aos="fade-up" data-aos-delay="100">
                            <img src="Content/img/NewBNT22.jpg" alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-md-4 mt-lg-5" data-aos="fade-up" data-aos-delay="200">
                            <img src="Content/img/NewBNT11.jpg" alt="Image" className="img-fluid" />
                        </div>
                    </div>

                </div>
            </div> */}



        </div>
    )
}

export default About