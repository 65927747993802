
import './App.css';
import './css/simon.css';
import './css/Badal.css';
import './css/aos.css';
import './css/style.css';



import { useEffect , useState } from 'react';
import { BrowserRouter as BrowseRouter } from 'react-router-dom';
import Footer from './Components/Home/Footer';
import Navigation from './Nav/Navigation.js';
import Navbar from './Components/Home/Navbar.js';


function App() {

  return (
    <div>
      <Navigation/>
    </div>
  );
}

export default App;
