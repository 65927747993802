import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
function Contact() {
    let navigate = useNavigate();
    const initValues = {
        name: "",
        email: "",
        subject: "",
        message: "",
    }
    const submit = (data) => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("subject", data.subject);
        formData.append("message", data.message);
   

        axios.post("https://bntserver.bntfoundation.com/ContactPost.php", formData).then((response) => {
            // navigate('/');
            window.location.href = '/contactconfirmation';
        })
    }
    const validation1 = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().required(),
        subject: Yup.string().required(),
        message: Yup.string().min(5).max(5000).required(),
    })
    return (
        <div>

            <div className="hero page-inner overlay"
                style={{ backgroundImage: `url('../../Content/img/slide/BNT1.jpg')` }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9 text-center mt-5">
                            <h1 className="heading" data-aos="fade-up">Contact Us</h1>

                            <nav aria-label="breadcrumb"
                                data-aos="fade-up"
                                data-aos-delay="200">
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row mb-5 mt-4">
                        <div className="col-lg-4 mb-5 mb-lg-0"
                            data-aos="fade-up"
                            data-aos-delay="100">
                            <div className="contact-info">
                                <div className="address mt-2">
                                    <i className="icon-room"></i>

                                    <p>
                                        6807 – 18 Ave SW Edmonton, <br /> AB T6X 0C9
                                    </p>
                                </div>

                                <div className="address mt-4">
                                    <i className="icon-envelope"></i>


                                    <p>
                                        seva@babananaktrust.ca
                                    </p>
                                </div>

                                <div className="address mt-5">
                                    <i className="icon-phone"></i>



                                    <p>780-616-5057</p>


                                </div>


                            </div>
                        </div>

                        {/************************************** formik **************************************************/}
                        <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                            <Formik initialValues={initValues} onSubmit={submit} validationSchema={validation1}>
                                <Form>

                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <ErrorMessage name="name" component="span" className='text-danger' />
                                            <Field
                                                name="name"
                                                className="form-control"
                                                placeholder="Your Name"
                                            />
                                        </div>
                                        <div className="col-6 mb-3">
                                            <ErrorMessage name="email" component="span" className='text-danger' />
                                            <Field
                                                name="email"
                                                className="form-control"
                                                placeholder="Your Email"
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <ErrorMessage name="subject" component="span" className='text-danger' />

                                            <Field
                                                name="subject"
                                                className="form-control"
                                                placeholder="Subject"
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <ErrorMessage name="message" component="span" className='text-danger' />

                                            <Field
                                                name="message"
                                                id=""
                                                className="form-control"
                                                placeholder="Message"
                                            />
                                        </div>

                                        <div className="col-12">
                                            <button type="submit" className="btn btn-secondary">Submit</button>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
















                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact;