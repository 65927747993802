import React from 'react'
import { Link, Route } from 'react-router-dom'
import { useState, useEffect } from 'react';

// import ROUTES from '../../Nav/Routes';

function Footer() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    useEffect(() => {
        // Update the current year when the component mounts
        setCurrentYear(new Date().getFullYear());
    }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

    return (
        <div>
            

            
        </div>
    )
}

export default Footer;