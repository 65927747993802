// **************************************************************** Home
import Footer from "../Components/Home/Footer.js";
import Navbar from "../Components/Home/Navbar.js";
import Index from "../Components/Home/Index.js";
import About from "../Components/Home/About.js";
import Contact from "../Components/Home/Contact.js";
import Donation from "../Components/Home/Donation.js";
import Blog from "../Components/Home/Blog.js";
import BlogDetail from "../Components/Home/BlogDetail.js";
import Gallery from "../Components/Home/Gallery.js";
import GalleryDetail from "../Components/Home/GalleryDetail.js";
import Team from "../Components/Home/Team.js";
import Layout from "../Components/Home/Layout.js";
import ContactConfirmation from "../Components/Home/ContactConfirmation.js";
import PrivacyPolicy from "../Components/Home/PrivacyPolicy.js";
import GetTheApp from "../Components/Home/GetTheApp.js";

//******************************************************** Admin 
import Home from "../Components/Admin/home.js";
import AdminLayout from "../Components/Admin/AdminLayout.js";
import ContactList from "../Components/Admin/ContactList.js";
import ContactDetail from "../Components/Admin/ContactDetail.js";
import AddTestimonial from "../Components/Admin/AddTestimonial.js";
import TestimonialList from "../Components/Admin/TestimonialList.js";
import TestimonialDetail from "../Components/Admin/TestimonialDetail.js";
import EditTestimonial from "../Components/Admin/EditTestimonial.js";
import GalleryCategory from "../Components/Admin/GalleryCategory.js";
import AddGalleryCategory from "../Components/Admin/AddGalleryCategory.js";
import GalleryCategoryDetail from "../Components/Admin/GalleryCategoryDetail.js";
import EditGalleryCategory from "../Components/Admin/EditGalleryCategory.js";
import GalleryList from "../Components/Admin/GalleryList.js";
import AddGallery from "../Components/Admin/AddGallery.js";
import EditGallery from "../Components/Admin/EditGallery.js";
import BlogList from "../Components/Admin/BlogList.js";
import AddBlog from "../Components/Admin/AddBlog.js";
import EditBlog from "../Components/Admin/EditBlog.js";
import BlogDetailA from "../Components/Admin/BlogDetailA.js";
import AddTeam from "../Components/Admin/AddTeam.js";
import TeamList from "../Components/Admin/TeamList.js";
import TeamDetail from "../Components/Admin/TestimonialDetail.js";
import LogIn from "../Components/Home/LogIn.js";
import Register from "../Components/Home/Register.js";
import Payment from "../Components/Admin/Payment.js";







const ROUTES = {
    //************************************************ Admin
    layout: {
        name: "/",
        component: <Layout />,
    },
    adminlayout: {
        name: "/",
        component: <AdminLayout />,
    },
    home: {
        name: "/home",
        component: <Home />,
    },
    contactlist: {
        name: "/contactlist",
        component: <ContactList />,
    },
    contactdetail: {
        name: "/contactdetail/:id",
        component: <ContactDetail />,
    },
    addtestimonial: {
        name: "/addtestimonial",
        component: <AddTestimonial />,
    },
    testimoniallist: {
        name: "/testimoniallist",
        component: <TestimonialList />,
    },
    testimonialdetail: {
        name: "/testimonialdetail/:id",
        component: <TestimonialDetail />,
    },
    edittestimonial: {
        name: "/edittestimonial/:id",
        component: <EditTestimonial />,
    },
    gallerycategory:{
        name:'/gallerycategory',
        component:<GalleryCategory/>
    },
    addgallerycategory:{
        name:'/addgallerycategory',
        component:<AddGalleryCategory/>
    },
    gallerycategorydetail:{
        name:'/gallerycategorydetail/:id',
        component:<GalleryCategoryDetail/>
    },
    editgallerycategory:{
        name:'/editgallerycategory/:id',
        component:<EditGalleryCategory/>
    },
    gallerylist:{
        name:'/gallerylist',
        component:<GalleryList/>
    },
    addgallery:{
        name:'/addgallery',
        component:<AddGallery/>
    },
    editgallery:{
        name:'/editgallery/:id',
        component:<EditGallery/>
    },
    bloglist:{
        name:'/bloglist',
        component:<BlogList/>
    },
    addblog:{
        name:'/addblog',
        component:<AddBlog/>
    },
    editblog:{
        name:'/editblog/:id',
        component:<EditBlog/>
    },
    blogdetaila:{
        name:'/blogdetaila/:id',
        component:<BlogDetailA/>
    },
    addteam:{
        name:'/addteam',
        component:<AddTeam/>
    },
    teamlist:{
        name:'/teamlist',
        component:<TeamList/>
    },
    teamdetail:{
        name:'/teamdetail/:id',
        component:<TeamDetail/>
    },
    logIn:{
        name:'/logIn',
        component:<LogIn/>
    },
    register:{
        name:'/register',
        component:<Register/>
    },
    payment:{
        name:'/payment',
        component:<Payment/>
    },

    // *********************************** home
    footer: {
        name: "/footer",
        component: <Footer />,
    },
    navbar: {
        name: "/navbar",
        component: <Navbar />,
    },
    index: {
        name: "/",
        component: <Index />,
    },
    about: {
        name: "/about",
        component: <About />,
    },
    contact: {
        name: "/contact",
        component: <Contact />,
    },
    donation: {
        name: "/donation",
        component: <Donation />,
    },
    blog: {
        name: "/blog",
        component: <Blog />,
    },
    blogdetail: {
        name: "/blogdetail/:id",
        component: <BlogDetail />,
    },
    gallery: {
        name: "/gallery",
        component: <Gallery />,
    },
    gallerydetail: {
        name: "/gallerydetail/:id",
        component: <GalleryDetail />,
    },
    team: {
        name: "/team",
        component: <Team />,
    },
    privacypolicy: {
        name: "/privacypolicy",
        component: <PrivacyPolicy/>,
    },
    contactconfirmation: {
        name: "/contactconfirmation",
        component: <ContactConfirmation />,
    },
    gettheapp: {
        name: "/gettheapp",
        component: <GetTheApp/>,
    },
};
export default ROUTES;