import React from 'react';


const home=() => {
  return (
    <div className="container1">
    <center>
        <h2>Welcome to admin panel</h2>
    </center>
  </div>
  )
}

export default home