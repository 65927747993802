import React from 'react';

function GetTheApp() {
    return (
        <div >
            <div className="hero page-inner overlay"
                style={{ backgroundImage: "url('Content/img/slide/BNT5.jpg')" }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9 text-center mt-5">
                            <h1 className="heading" data-aos="fade-up">Get Saathi App</h1>
                            <nav aria-label="breadcrumb"
                                data-aos="fade-up"
                                data-aos-delay="200">
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-5 py-5">
                <div className="my-5">

                    <h1 className="text-secondary mb-3" data-aos="fade-up">
                        Welcome to Saathi App
                    </h1>
                    <p className="text-gray-dark" data-aos="fade-up" data-aos-delay="300">
                        Meet Saathi App, your friendly helper brought to you by the BNT Foundation. In today’s busy world, it can be tough to handle emotions and stress. That’s where Saathi comes in.

                        Saathi is here to support you when you're feeling down, anxious, or overwhelmed. It offers easy-to-use tools, helpful resources, and gentle guidance to help you manage your mental health. Whether you need a calming exercise, tips for feeling better, or just a comforting voice, Saathi is designed to be there for you.

                        Think of Saathi as a caring friend you can keep in your pocket. You don’t have to go through tough times alone—Saathi is here to walk with you on your journey to feeling better. Let’s take those steps together towards a happier, healthier you!
                    </p>
                </div>
                <hr data-aos="fade-up" data-aos-delay="400" />
                <div className="my-5">
                    <h2 className="text-secondary mb-3" data-aos="fade-up">
                        What is Saathi App?
                    </h2>
                    <p className="text-gray-dark" data-aos="fade-up" data-aos-delay="100">
                        Saathi App is your personal partner in mental wellness, designed to be there for you when life feels overwhelming. If you're feeling anxious, stressed, or depressed, Saathi offers a comforting hand to help you navigate those challenging emotions.

                        With Saathi, you’ll find a variety of supportive features tailored to your needs. Access a wealth of mental health resources that provide valuable information and tools to help you understand and manage your feelings. Dive into stress-relief exercises that guide you through calming techniques, allowing you to relax and regain your peace of mind.

                    </p>
                    <p className="text-gray-dark" data-aos="fade-up" data-aos-delay="200">
                        One of the unique aspects of Saathi is the safe space it creates for you to express your emotions. You can share your feelings and experiences without judgment, knowing that you’re in a supportive environment.

                        But that’s not all! Saathi also allows you to listen to Gurbani, offering soothing spiritual music that can bring you comfort and peace. You can immerse yourself in uplifting articles and stories that inspire and empower you. Additionally, the app gives you the opportunity to describe your own stories, creating a personal narrative that can help you reflect and heal.
                    </p>
                </div>
                <hr  data-aos="fade-up" data-aos-delay="400" />
                <div className="my-5">
                    <h3 className="text-secondary mb-3" data-aos="fade-up">
                        Why Choose Saathi?
                    </h3>
                    <ul>
                        <li>
                            <h6 className="text-primary mb-3" data-aos="fade-up">
                                Support at Your Fingertips
                            </h6>
                            <p className="text-gray-dark" data-aos="fade-up">
                                Instant access to helpful resources, professional advice, and self-care tools.
                            </p>
                        </li>
                        <li>
                            <h6 className="text-primary mb-3" data-aos="fade-up">
                                Personalized Experience
                            </h6>
                            <p className="text-gray-dark" data-aos="fade-up">
                                Tailor the app to fit your needs, whether you’re looking for Gurbaani, Stories, motivational content, or just a comforting presence.
                            </p>
                        </li>
                        <li>
                            <h6 className="text-primary mb-3" data-aos="fade-up">
                                Confidential & Safe
                            </h6>
                            <p className="text-gray-dark" data-aos="fade-up">
                                Your privacy is our priority. All information shared through the app remains confidential, ensuring a safe space for your mental health journey.
                            </p>
                        </li>
                        <li>
                            <h6 className="text-primary mb-3" data-aos="fade-up">
                                24/7 Assistance
                            </h6>
                            <p className="text-gray-dark" data-aos="fade-up">
                                Whether it’s day or night, Saathi is always here for you with tools to help you navigate your emotions.
                            </p>
                        </li>
                    </ul>
                </div>
                <hr  data-aos="fade-up" data-aos-delay="400" />
                <div className="my-5">
                    <h2 className="text-secondary mb-3" data-aos="fade-up">
                        How Does Saathi Help?
                    </h2>
                    <p className="text-gray-dark" data-aos="fade-up" data-aos-delay="100">
                        We understand that mental health challenges can feel isolating, but Saathi helps you build resilience and regain control over your emotions.
                        The app’s holistic approach empowers you with practical tools and emotional support, helping you to move forward with confidence.
                    </p>
                </div>
                
                
                <div className="my-5">
                    <h2 className="text-secondary mb-3" data-aos="fade-up">
                    Please Note:
                    </h2>
                    <h5 className="text-gray-dark" data-aos="fade-up" data-aos-delay="100">
                    This app is currently available only for Android devices and is fully compatible with tablets for a seamless user experience.
                    </h5>
                </div>
                <div className="col-12 text-center">
                    <a href='https://bntfoundation.com/App/Saathi.apk' className='btn btn-secondary py-3 px-5' data-aos="fade-up" data-aos-delay="100" ><i class="fa-brands fa-android mr-3" style={{fontSize:22,}}></i> Download Saathi App APK File</a>
                </div>
            </div>


        </div>
    )
}
export default GetTheApp;